import { Box, Card, CardContent, CardMedia } from "@mui/material";
import ThankYouGif from '../../../Assets/Images/thanks.gif'
const WelcomePage = () => {
    return (
        <Box className="welcomePage">
            <Card className="messageCard">
                <CardMedia>
                    <img src={ThankYouGif} alt="gif" />
                </CardMedia>
                <CardContent className="cardText">
                    <p>We've sent your login credentials on your email.</p>
                    <small>please do not refresh the page you will be redriecting on login page</small>
                </CardContent>
            </Card>
        </Box>
    )
}
export default WelcomePage;