import { Box, Container, Grid, Typography } from "@mui/material";
import react from "react";
import "./Style.css";


const DummyData = [
    {
        text: "1.  Request customers to sign quotes or estimates you send"
    },
    {
        text: "2.   Get any document eSigned in person on any device"
    },
    {
        text: "3.  Create fill & sign documents and share with clients via email or Whatsapp."
    },
]

const DummyDataTwo = [
    {
        text: "1. Save Time: Document digitization allows you to easily transfer important information onto your document and stay organized without having to manually do anything."
    },
    {
        text: "2. Go Paperless: eSign assures that your entire business can be done from a computer, tablet, or phone."
    },
    {
        text: "3. Retain Customers: Enabling the option to have customers sign estimates, will help in lead to job turnover."
    },
    {
        text: "4. Be More Professional: Everyone knows that eSign is the new way to do business, be one with the professionals."
    },
    {
        text: "5. Get any document eSigned in person on any device"
    },
    {
        text: "6. Don’t pay for a third party eSign software: ePackBook has it all, no need to pay for an eSign software."
    },
]


const DigitalSignatureView = () => {

    return (

        <>
            <Container className="digitalSignature">
                <Grid Container>
                    <Grid item sx={12}>
                        <Typography variant="h4" style={{ marginBottom: 30, fontSize: 26 }}>
                            eSignature
                        </Typography>
                        <Typography variant="h6" style={{ marginBottom: 30, fontSize: 18 }}>
                            eSign:  quotes or documents signed via web, App or in person
                        </Typography>
                        <p style={{ marginBottom: 30 }}>Looking for a simple way to collect signatures online and on the job? ePackBook has innovated and built an entire eSignature. The eSigned documents allow for authenticated tracking, and is the only legitimate eSign system.

                        </p>

                        <Typography variant="h6" style={{ marginBottom: 30, fontSize: 18 }}>
                            With ePackBook document tracking and authentication you can:
                        </Typography>

                        <ol>
                            {DummyData.map((_, i) => {
                                const txtArr = _.text.split(":");
                                return (

                                    <li key={i} style={{ marginBottom: 30, listStyle: 'none', lineHeight: 1.5, textalign: "justify" }}><span style={{ fontWeight: '600' }}>{txtArr[0]} &#58;</span>{txtArr[1]} </li>
                                )
                            })
                            }
                        </ol>

                        <Typography variant="h6" style={{ marginBottom: 30, fontSize: 18 }}>
                            Now with eSignatures on ePackBook you can

                        </Typography>

                        <ol>
                            {DummyDataTwo.map((_, i) => {
                                const txtArr = _.text.split(":");
                                return (

                                    <li key={i} style={{ marginBottom: 30, listStyle: 'none', lineHehight: 1.5, textAlign: "justify" }}><span style={{ fontWeight: '600' }}>{txtArr[0]} &#58;</span>{txtArr[1]} </li>
                                )
                            })
                            }
                        </ol>

                    </Grid>
                </Grid>
            </Container>
        </>
    )

    {/* 
    
   
   
    
    
    */}
};

export default DigitalSignatureView;