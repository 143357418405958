import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  //send email
  requesting: false,
  success: false,
  fail: false,
  data: null,
  //verify email
  verifying: false,
  verifySuccess: false,
  verifyFail: false,
  verifyData: null,
};

const FreeAccountSlice = createSlice({
  name: "FreeAccountSlice",
  initialState: initialState,
  reducers: {
    // **** Send Email Actions ****
    sendEmailRequst: (state, action) => {
      state.requesting = true;
    },
    sendEmailSuccess: (state, action) => {
      state.requesting = false;
      state.success = true;
      state.data = action.payload;
    },
    sendEmailFail: (state, action) => {
      state.requesting = false;
      state.fail = true;
      state.data = action.payload;
    },
    resetSendEmailState: (state, action) => {
      state.requesting = false;
      state.fail = false;
      state.success = false;
      state.data = null;
    },
    // ***** Verify Email Actions *****
    verifyEmailRequst: (state, action) => {
      state.verifying = true;
    },
    verifyEmailSuccess: (state, action) => {
      state.verifying = false;
      state.verifySuccess = true;
      state.verifyData = action.payload;
    },
    verifyEmailFail: (state, action) => {
      state.verifying = false;
      state.verifyFail = true;
      state.verifyData = action.payload;
    },
    resetVerifyEmailState: (state, action) => {
      state.verifying = false;
      state.verifySuccess = false;
      state.verifyFail = false;
      // state.verifyData = null;
    },
    resetAll: (state, action) => {
      state.requesting = false;
      state.success = false;
      state.fail = false;
      state.data = null;
      state.verifying = false;
      state.verifySuccess = false;
      state.verifyFail = false;
      state.verifyData = null;
    },
  },
});
export const {
  sendEmailRequst,
  sendEmailSuccess,
  sendEmailFail,
  resetSendEmailState,
  verifyEmailRequst,
  verifyEmailSuccess,
  verifyEmailFail,
  resetAll,
  resetVerifyEmailState,
} = FreeAccountSlice.actions;

export default FreeAccountSlice.reducer;
