import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  requesting: false,
  success: false,
  fail: false,
  data: null,
};

const RegisterForDemoSlice = createSlice({
  name: "RegisterForDemoSlice",
  initialState: initialState,
  reducers: {
    registerForDemoRequest: (state, action) => {
      state.requesting = true;
    },
    registerForDemoSuccess: (state, action) => {
      state.requesting = false;
      state.success = true;
      state.data = action.payload;
    },
    registerForDemoFail: (state, action) => {
      state.requesting = false;
      state.fail = true;
      state.data = action.payload;
    },
    reset: (state, action) => {
      state.requesting = false;
      state.success = false;
      state.fail = false;
      state.data = null;
    },
  },
});
export const {
  registerForDemoRequest,
  registerForDemoSuccess,
  registerForDemoFail,
  reset,
} = RegisterForDemoSlice.actions;

export default RegisterForDemoSlice.reducer;
