import { Box, Breadcrumbs, Container, Grid, IconButton, Typography, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { Link } from "react-router-dom";
import './style.css';
import { useState } from "react";
import { BiSearch } from "react-icons/bi";
import { IoMdClose } from "react-icons/io";

const ComponentSubHeader = ({ heading, current, style, isSearchAble }) => {
    const [showSearchList, setShowSearchList] = useState({
        action: false,
        data: null
    });
    const searchData = [
        {
            lable: 'sandeep',
            src: '#'
        },
        {
            lable: 'lalit',
            src: '#'
        },
        {
            lable: 'vijay',
            src: '#'
        },
        {
            lable: 'nomesh',
            src: '#'
        },
        {
            lable: 'salim',
            src: '#'
        }
    ]

    const handleChangeSearchBar = (e) => {
        const searchInput = e.target.value.toLowerCase();
        if (searchInput.length > 2) {
            const filterdVal = searchData.filter((d) => d.lable.includes(searchInput) && d)
            setShowSearchList({
                action: true,
                data: filterdVal
            })

        } else if (searchInput.length < 3) {
            setShowSearchList({
                action: false,
                data: null
            })
        }

    }
    const handleClearSearBar = () => {
        document.getElementById("searchInput").value = '';
        setShowSearchList({
            action: false,
            data: null
        })

    }
    return (
        <Box sx={{ background: '#212b36', mb: 4 }}>
            <Container>
                <Grid container>
                    <Grid item xs={12} lg={6} sx={{ p: 0, m: 0 }} >
                        <Box className="headingBox" sx={style}>
                            <Typography style={{ color: "#fff" }} variant="h4">
                                {heading}
                            </Typography>
                            <Breadcrumbs aria-label="breadcrumb" style={{ color: "#fff" }}>
                                <Link color="#fff" to="/" >
                                    Home
                                </Link>
                                <Typography>
                                    {current}
                                </Typography>
                            </Breadcrumbs>
                        </Box>
                    </Grid>
                    {
                        isSearchAble && <Grid item xs={12} lg={6} alignItems={'center'} display={'flex'} justifyContent={'end'} >
                            <Box className="searchBarWrapper">
                                <Box className="inputBar" sx={{ borderRadius: showSearchList?.data?.length > 0 ? ' 8px 8px 0 0' : '8px' }}>
                                    <BiSearch size={24} color='#8b8b8b' />
                                    <input
                                        id='searchInput'
                                        className="searchBar"
                                        placeholder="Search Keyword"
                                        onChange={handleChangeSearchBar}
                                    />
                                    {
                                        showSearchList.action && (
                                            <IconButton onClick={handleClearSearBar}>
                                                <IoMdClose size={16} />
                                            </IconButton>
                                        )
                                    }
                                </Box>
                                {showSearchList.action && showSearchList.data.length > 0 && (
                                    <>
                                        <List sx={{ backgroundColor: 'white', p: 0, width: '100%' }} className="searchList"  >
                                            {showSearchList.data.map((_, i) => (
                                                <>
                                                    <ListItem disablePadding key={i} sx={{ borderBottom: showSearchList.data.length - 1 === i ? "none" : '1px solid #5A5B5C' }} >
                                                        <ListItemButton component="a" href={_.src} onClick={() => setShowSearchList(
                                                            {
                                                                action: false,
                                                                data: showSearchList.data
                                                            }
                                                        )}  >
                                                            <ListItemText primary={_.lable} />
                                                        </ListItemButton>
                                                    </ListItem>
                                                </>
                                            ))}
                                        </List>
                                    </>
                                )}
                            </Box>
                        </Grid>
                    }
                </Grid>
            </Container>
        </Box>
    )

}

export default ComponentSubHeader;