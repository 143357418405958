import { configureStore } from "@reduxjs/toolkit";
import { rootSaga } from "../Sagas";
import createSagaMiddleware from "@redux-saga/core";
import FreeAccountSlice from "./Slices/FreeAccount.Slice";
import RegisterCompanySlice from "./Slices/RegisterCompany.Slice";
import RegisterForDemoSlice from "./Slices/RegisterForDemo.Slice";
import backdropSlice from "./Slices/backdrop";

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

export const store = configureStore({
  reducer: {
    FreeAccountSlice,
    RegisterCompanySlice,
    RegisterForDemoSlice,
    backdropSlice,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false
    }).concat(middleware);
  },
});

sagaMiddleware.run(rootSaga);
