import { Box } from "@mui/material";
import React from "react";
import './service_item.css';

const ServiceItem = ({ data }) => {
    return (
        <Box sx={{ background: data.color }} id="breathing-button" className="service-item">{data.name}</Box>
    );

}

export default ServiceItem;