import { Box, Typography } from "@mui/material";
import ComponentSubHeader from "../UIComponents/ComponentSubHeader";

const PaymentFailed = () => {
  return (
    <>
      <ComponentSubHeader
        heading={"Payment Failed"}
        current={"Payment Failed"}
      />
      <Box sx={{ textAlign: "center", height: "40vh" }}>
        <Typography variant="h3" color={"red"}>
          Your Payment is Failed
        </Typography>
        <Typography variant="h4" marginTop={2} color={"#5cb85c"}>
          Please try again.
        </Typography>
      </Box>
    </>
  );
};
export default PaymentFailed;
