import axios from "axios";
import * as ENDPOINTS from "./Endpoints";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

axiosInstance.interceptors.response.use((response) => {
  switch (response.status) {
    case 200:
      const { data, status } = response.data;
      if (status >= 400 || status === 203) {
        return Promise.reject({ message: data });
      }
      return data;
    case 402:
      return Promise.reject("Server is offline");
  }
});

export const getRequest = async (apiEndpoint) => {
  try {
    const response = await axiosInstance.get(apiEndpoint);
    console.log(`get call response: ${JSON.stringify(response)}`);
    return response;
  } catch (error) {
    console.log(`get call error: ${JSON.stringify(error)}`);
    throw error;
  }
};

export const postRequest = async (apiEndpoint, data) => {
  try {
    const response = await axiosInstance.post(apiEndpoint, data);
    console.log(`post call response: ${JSON.stringify(response)}`);
    return response;
  } catch (error) {
    console.log(`post call error: ${JSON.stringify(error)}`);
    throw error;
  }
};

export function handleFileUpload(file) {
  const formData = new FormData();
  formData.append("files", file);
  const url =
    process.env.REACT_APP_PACKERS_API_BASE_URL + ENDPOINTS.UPLOAD_FILE;
  return axios
    .post(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((response) => {
      switch (response.status) {
        case 200:
          const { data, status } = response.data;
          if (status >= 400 || status === 203) {
            return Promise.reject({ message: data });
          }
          return data;
        case 402:
          return Promise.reject("Server is offline");
      }
    });
}
