import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
  openWelcome: false
};

const backdropSlice = createSlice({
  name: "backdrop",
  initialState: { ...initialState },
  reducers: {
    showBackdrop(state, action) {
      return { ...state, open: true };
    },
    showWelcomeBackdrop(state) {
      return { ...state, openWelcome: true }
    },
    resetBackdrop() {
      return initialState;
    },
  },
});

export const { showBackdrop, resetBackdrop, showWelcomeBackdrop } = backdropSlice.actions;

export default backdropSlice.reducer;
