import { Grid, Box } from "@mui/material";
import React from "react";
import ServiceItem from "../Components/ServiceItem/ServiceItem";

const servicesArr = [
    {
        name: "Bilty Module",
        color: '#00A1E5'
    },
    {
        name: "Quotation Module",
        color: '#ffa200'
    },
    {
        name: "Money Receipt Module",
        color: '#ff4800'
    },
    {
        name: "Vehicle Condition Module",
        color: '#00A1E5'
    },
    {
        name: "Invoice Module",
        color: '#ffa200'
    },
    {
        name: "Packing List Module",
        color: '#ff4800'
    }
]
const Services = () => {

    return (
        <Grid container spacing={2}>
            {servicesArr.map((item, index) => {
                return (
                    <Grid item md={3} lg={2} key={`${index}`}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <ServiceItem data={item} key={index} />
                        </Box>
                    </Grid>
                )
            })}
        </Grid>
    );
}

export default Services;