import React from "react";
import { TextField, MenuItem, Grid, Autocomplete } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { getCityInfoByPincode } from "../../utils/utils";
import { Fragment } from "react";

function StateSelect(props) {
  const {
    state,
    city,
    postalCode,
    postOffice,
    district,
    handleStateChange,
    handleCityChange,
    handlePostalCodeChange,
    handleDistrictChange,
    handlePostOfficeNameChange,
    resetPostalCodeFields = false,
  } = props;
  const [states, setStates] = useState({
    show: false,
    data: [],
  });
  const [cities, setCities] = useState({
    show: false,
    data: [],
  });
  const [districts, setDistricts] = useState({
    show: false,
    data: [],
  });
  const [postOffices, setPostOffices] = useState({
    show: false,
    data: [],
  });
  const [isPostalCodeChanged, setIsPostalCodeChanged] = useState(false);
  const [locations, setLocations] = useState({});

  const handleReset = () => {
    setLocations([]);
    setStates({
      show: false,
      data: [],
    });
    setCities({
      show: false,
      data: [],
    });
    setDistricts({
      show: false,
      data: [],
    });
    setPostOffices({
      show: false,
      data: [],
    });
  };

  useEffect(() => {
    handleReset();
    setIsPostalCodeChanged(false);
  }, [resetPostalCodeFields]);

  useEffect(() => {
    if (locations.length) {
      // setting states
      const all_states = locations.map((item) => item.state);
      const first_state = all_states[0];
      setStates({
        show: true,
        data: [...new Set(all_states)],
      });
      handleStateChange(first_state);

      // setting cities
      const all_cities = locations.map((item) => item.city);
      const first_city = all_cities[0];
      setCities({
        show: true,
        data: [...new Set(all_cities)],
      });
      handleCityChange(first_city);

      // setting districts
      const all_districts = locations.map((item) => item.district);
      const first_district = all_districts[0];
      setDistricts({
        show: true,
        data: [...new Set(all_districts)],
      });
      handleDistrictChange(first_district);

      // setting post offices
      const all_post_offices = locations.map((item) => item.postOfficeName);
      const first_postOffice = all_post_offices[0];
      setPostOffices({
        show: true,
        data: all_post_offices,
      });
      handlePostOfficeNameChange(first_postOffice);
    }
  }, [locations]);

  const _getCityInfoByPincode = async (value) => {
    const result = await getCityInfoByPincode(value);
    if (result && result.length > 0) {
      setLocations(result);
      setIsPostalCodeChanged(true);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} lg={3}>
        <TextField
          id={postalCode.id}
          label="Pincode"
          variant="outlined"
          size="small"
          fullWidth
          error={postalCode.error}
          value={postalCode.value}
          helperText={postalCode.error}
          onChange={(e) => {
            handlePostalCodeChange(e.target.value);
          }}
          onBlur={(e) => {
            const { value } = e.target;
            if (value.trim().length > 0 && value.length === 6) {
              _getCityInfoByPincode(value);
            }
          }}
          autoComplete={false}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      {isPostalCodeChanged ? (
        <Fragment>
          <Grid item xs={12} sm={6} lg={3}>
            <TextField
              label="State"
              variant="outlined"
              size="small"
              fullWidth
              select
              disabled={!states.show}
              value={state.value}
              onChange={(e) => handleStateChange(e.target.value)}
            >
              <MenuItem value={""}>Select</MenuItem>
              {states.data.map((state, index) => (
                <MenuItem key={index} value={state}>
                  {state}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6} lg={3}>
            <Autocomplete
              // inputValue={formik?.values[city.id]}
              value={city.value}
              onInputChange={(event, newValue) => {
                handleCityChange(newValue);
              }}
              disabled={!cities.show}
              size="small"
              options={cities.data}
              fullWidth
              renderInput={(params) => <TextField {...params} label="City" />}
            />
          </Grid>

          {/* <Grid item xs={12} sm={6} lg={3}>
        <TextField
          label="District"
          variant="outlined"
          size="small"
          fullWidth
          disabled={!districts.show}
          value={district.value}
          onChange={(e) => handleDistrictChange(e.target.value)}
          select
        >
          <MenuItem value={""}>Select</MenuItem>
          {districts.data.map((option, index) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </Grid> */}

          <Grid item xs={12} sm={6} lg={3}>
            <Autocomplete
              // inputValue={formik?.values[city.id]}
              onInputChange={(event, newValue) => {
                handlePostOfficeNameChange(newValue);
              }}
              disabled={!postOffices.show}
              value={postOffice.value}
              size="small"
              options={postOffices.data}
              fullWidth
              renderInput={(params) => (
                <TextField {...params} label="Locality" />
              )}
            />
          </Grid>
        </Fragment>
      ) : (
        <Fragment>
          <Grid item xs={12} sm={6} lg={3}>
            <TextField
              label="State"
              variant="outlined"
              size="small"
              fullWidth
              disabled
              value={state.value}
            />
          </Grid>

          <Grid item xs={12} sm={6} lg={3}>
            <TextField
              label="City"
              variant="outlined"
              size="small"
              fullWidth
              disabled
              value={city.value}
            />
          </Grid>

          {/* <Grid item xs={12} sm={6} lg={3}>
        <TextField
          label="District"
          variant="outlined"
          size="small"
          fullWidth
          disabled={!districts.show}
          value={district.value}
        />
      </Grid> */}

          <Grid item xs={12} sm={6} lg={3}>
            <TextField
              label="Locality"
              variant="outlined"
              size="small"
              fullWidth
              disabled
              value={postOffice.value}
            />
          </Grid>
        </Fragment>
      )}
    </Grid>
  );
}

export default StateSelect;
