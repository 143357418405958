import React from "react";
import { Backdrop as MuiBackdrop, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import WelcomePage from "./WelcomeMessage";


function Backdrop() {
  const open = useSelector((state) => state.backdropSlice.open);
  const openWelcome = useSelector((state) => state.backdropSlice.openWelcome);
  return (
    <>
      {openWelcome ? <WelcomePage /> : <MuiBackdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1000 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </MuiBackdrop>}
    </>
  );
}

export default Backdrop;
