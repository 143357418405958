import { Box } from "@mui/system";
import react from "react";
import "./Style.css";


const MobileAppView = () => {

    return (
        <Box className="mobileView">
            <h1>MobileAppView</h1>
        </Box>
    )
};
export default MobileAppView;