import {
  Avatar,
  Box,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  Typography,
} from "@mui/material";
import React, { useEffect, Fragment } from "react";
import { IoLocationSharp, IoMail } from "react-icons/io5";
import { FaMobileAlt } from "react-icons/fa";
import Map from "../../common/map";
import "../ContactUs/style.css";
import ComponentSubHeader from "../UIComponents/ComponentSubHeader";
import { Contactform } from "./Contactform";

const addresscontfirst = [
  {
    icon: <IoLocationSharp size={20} color="#444444" />,
    text: "Unit No. 537-538, Spaze iTech Park, Tower B3, Sector 49, Gurugram-122018, Haryana, INDIA ",
  },
  {
    icon: <IoMail size={20} color="#444444" />,
    text: "info@epackbook.in",
  },
  {
    icon: <FaMobileAlt size={20} color="#444444" />,
    text: "+91-9355771100",
  },
];
const addresscontsecond = [
  {
    icon: <IoLocationSharp size={20} color="#444444" />,
    text: "Unit No. 537-538, Spaze iTech Park, Tower B3, Sector 49, Gurugram-122018, Haryana, INDIA ",
  },
  {
    icon: <IoMail size={20} color="#444444" />,
    text: "info@epackbook.in",
  },
  // {
  //     icon: <FaMobileAlt size={20} color="#444444" />,
  //     text: "+91-000000000"
  // }
];

const Contactus = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <head>
        <title>
          Connect with ePackBook - India’s No. 1 Packers and Movers CRM for any
          help
        </title>
        <meta
          name="description"
          content="Facing any difficulty using ePackBook -the  Relocation Management Software, Please feel free to get in touch with us at +91-9355771100 or write to us at info@epackbook.in or fill up the form."
        />
      </head>
      <ComponentSubHeader heading={"Contact Us"} current="Contact Us" />
      <Container>
        <Box className="contactWrapper">
          <Grid container>
            <Grid item xs={12} md={6}>
              <Box className="addresswrapperfirst">
                <Typography variant="h5">Our Address</Typography>
                <Box className="addressinnerItem">
                  <Typography variant="h6">Head Office</Typography>
                  <List>
                    {addresscontfirst.map((_, i) => (
                      <ListItem disablePadding key={i}>
                        <ListItemAvatar>
                          <Avatar sx={{ marginBottom: "10px" }}>
                            {_.icon}
                          </Avatar>
                        </ListItemAvatar>
                        <a to="/" target="_blank">
                          {_.text}
                        </a>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Box>
              <Divider
                size={2}
                variant="fullWidth"
                sx={{ backgroundColor: "#444444", width: "95%" }}
              />
              <Box
                className="mapWrapper"
                height={"450px"}
                sx={{ marginTop: "35px" }}
              >
                <Map />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className="contactformWrapper">
                <Typography mb={2} variant="h5">
                  Get in Touch
                </Typography>
                <Contactform />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Fragment>
  );
};
export default Contactus;
