export const COMPANY_TYPES_CONSTANTS = {
    SOLE_PROPRIETORSHIP: {
      key: "SOLE_PROPRIETORSHIP",
      text: "Sole proprietorship",
    },
    PARTNERSHIP: { key: "PARTNERSHIP", text: "Partnership" },
    LLP: { key: "LLP", text: "LLP" },
    PRIVATE_LIMITED: { key: "PRIVATE_LIMITED", text: "Private Limited" },
  };
  
  export const ISO_CERTIFICATE_TYPES_CONSTANTS = {
    "ISO 9001:2008": { key: "9001:2008", text: "ISO 9001:2008" },
    "ISO 9001:2015": { key: "9001:2015", text: "ISO 9001:2015" },
    NA: { key: "NA", text: "NA" },
  };