import { Grid, Typography, Container } from "@mui/material";
import { Box } from "@mui/system";
import react from "react";
import "./Style.css";



const DummyData = [
    {
        text: "1. Preset: Preset emails means you do not have to write a new email for each client. Just hit send, and a preset email will be sent"
    },
    {
        text: "2. Customizable: Easily change the layout and content of your emails"
    },
    {
        text: "3.Personalize: Use the available commands to automatically enter information into the emails, such as the client’s name"
    },
    {
        text: "4.Deadline: Want to set hear back from you clients within a certain timeframe? Set an expiration date for the quote"
    },

]



const CreateNSendQuotationView = () => {

    return (

        <>
            <Container className="createnSendQuotation">
                <Grid Container>
                    <Grid item sx={12}>
                        <Typography variant="h4" style={{ marginBottom: 30, fontSize: 26 }}>
                            Quotation
                        </Typography>
                        <p style={{ marginBottom: 30 }}>Getting quotes for your clients quickly is important. With ePackBook, you can easily generate quotes and send them out to your customers in customizable emails or in Whatsapp as well.
                        </p>

                        <ol>
                            {DummyData.map((_, i) => {
                                const txtArr = _.text.split(":");
                                return (

                                    <li key={i} style={{ marginBottom: 30, listStyle: 'none', lineHeight: 1.5, textAlign: "justify" }}><span style={{ fontWeight: '600' }}>{txtArr[0]} &#58;</span>{txtArr[1]} </li>
                                )
                            })
                            }
                        </ol>

                    </Grid>
                </Grid>
            </Container>
        </>
    )

};

export default CreateNSendQuotationView;