import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CompanyRegistrationComponent from "../Components/CompanyRegistration";
import { handleFileUpload } from "../Network/ApiClient";
import { registerCompanyRequest } from "../Redux/Slices/RegisterCompany.Slice";
import { resetBackdrop, showBackdrop } from "../Redux/Slices/backdrop";
import { COMPANY_TYPES_CONSTANTS } from "../Constant";
import Validation from "../utils/Validations";
const CompanyRegistration = () => {
  const navigate = useNavigate();
  const alphaNumericRegExp = /[^a-z0-9-]/gi;
  const websiteRegExp =
    /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
  const gstNoRegExp =
    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;

  const { verifyData } = useSelector((state) => state.FreeAccountSlice);
  const [iba, setIba] = useState();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      companyName: "",
      companyPunchLine: "",
      contactPerson: "",
      email: verifyData?.user?.email || "",
      phone: "",
      mobile: verifyData?.user?.phone_number || "",
      address: "",
      landmark: "",
      pincode: "",
      state: "",
      city: "",
      postOffice: "",
      district: "",
      country: "india",
      companyWebsite: "",
      gstNo: "",
      udyamRegistration: "",
      ibaApprovalCode: "",
      ibaCodeValidTill: "",
      shopAct: "",
      isoCerificate: "",
      companyType: "",
      panNo: "",
      incNo: "",
      signature: "",
      companyLogo: "",
      companyProfilePdf: "",
    },
    validate: (values) => {
      const validations = new Validation();
      const errors = {};

      if (!values.companyName) {
        errors.companyNameErr = "Please enter company name";
      }

      if (!values.contactPerson) {
        errors.contactPersonErr = "Please enter contact preson";
      }

      if (!values.email) {
        errors.emailErr = "Please enter email";
      } else if (!validations.isValidEmailAddress(values.email)) {
        errors.emailErr = "Please enter a valid email address";
      }

      if (
        values.companyWebsite &&
        !values.companyWebsite.match(websiteRegExp)
      ) {
        errors.companyWebsiteErr = "Please enter valid website url ";
      }

      if (!values.pincode) {
        errors.pincodeErr = "Please enter pincode";
      } else if (!validations.isValidPincode(values.pincode)) {
        errors.pincodeErr = "Please enter valid pincode";
      }

      if (!values.mobile) {
        errors.mobileErr = "Please enter mobile number";
      }
      if (!validations.isValidIndianMobile(values.mobile)) {
        errors.mobileErr = "Please enter a valid 10 digit mobile number";
      }

      if (values.phone.length > 0 && !validations.isValidPhone(values.phone)) {
        errors.phoneErr = "Please enter valid 11 digit phone number";
      } else if (values.phone && values.mobile === values.phone) {
        errors.phoneErr =
          "Please enter different phone number or mobile number";
      }

      if (!values.address) {
        errors.addressErr = "Please enter address";
      }

      if (!values.country) {
        errors.countryErr = "Please enter country";
      }
      if (values.gstNo && !values.gstNo.match(gstNoRegExp)) {
        errors.gstNoErr = "Please enter Valid GST No";
      }
      if (values.udyamRegistration.match(alphaNumericRegExp)) {
        errors.udyamRegistrationErr =
          "Please enter valid udyam registration number";
      }
      if (values.udyamRegistration.toString().length > 19) {
        errors.udyamRegistrationErr =
          "Please enter valid udyam registration number";
      }
      if (!values.isoCerificate) {
        errors.isoCerificateErr = "Please enter ISO Certificate Type";
      }
      if (iba && !values.ibaApprovalCode) {
        errors.ibaApprovalCodeErr = "Please enter IBA approval code";
      }
      if (iba && values.ibaApprovalCode.match(alphaNumericRegExp)) {
        errors.ibaApprovalCodeErr = "Please enter valid IBA approval code";
      }
      if (values.ibaApprovalCode.length > 8) {
        errors.ibaApprovalCodeErr = "Please enter valid IBA approval code";
      }
      if (iba && !values.ibaCodeValidTill) {
        errors.ibaCodeValidTillErr = "Please select IBA code valid till";
      }
      if (!values.companyType) {
        errors.companyTypeErr = "Please selest company type";
      }
      if (
        values.companyType &&
        [
          COMPANY_TYPES_CONSTANTS.SOLE_PROPRIETORSHIP.key,
          COMPANY_TYPES_CONSTANTS.PARTNERSHIP.key,
        ].includes(values.companyType) &&
        !values.panNo
      ) {
        errors.panNoErr = "Please enter pan number";
      }

      if (
        values.companyType &&
        [
          COMPANY_TYPES_CONSTANTS.SOLE_PROPRIETORSHIP.key,
          COMPANY_TYPES_CONSTANTS.PARTNERSHIP.key,
        ].includes(values.companyType) &&
        !validations.isValidPanCard(values.panNo)
      ) {
        errors.panNoErr = "Please enter valid 10 character pan no.";
      }
      if (
        values.companyType &&
        [
          COMPANY_TYPES_CONSTANTS.LLP.key,
          COMPANY_TYPES_CONSTANTS.PRIVATE_LIMITED.key,
        ].includes(values.companyType) &&
        !values.incNo
      ) {
        errors.incNoErr = "Please enter incorporation number";
      }
      console.log("errors", errors);
      return errors;
    },
    validateOnChange: false,
    onSubmit: async (vals) => {
      try {
        dispatch(showBackdrop());
        const values = structuredClone(vals);
        // uploading images
        const obj = {};
        if (values.companyLogo) {
          obj.logo = await handleFileUpload(values.companyLogo);
          obj.logo = obj.logo[0];
        }
        if (values.companyProfilePdf) {
          obj.profile = await handleFileUpload(values.companyProfilePdf);
          obj.profile = obj.profile[0];
        }
        const new_values = {
          company: {
            ...obj,
            type: values.companyType,
            name: values.companyName,
            punchline: values.companyPunchLine,
            ownerName: values.contactPerson,
          },
          phone: {
            primary: {
              number: values.mobile,
            },
            landlineNumber: values.phone,
          },
          iba: {
            code: values.ibaApprovalCode,
            expiryDate: values.ibaCodeValidTill,
          },
          email: values.email,
          address: {
            street: values.address,
            landmark: values.landmark,
            country: values.country,
            state: values.state,
            city: values.city,
            postalCode: values.pincode,
            postOffice: values.postOffice,
            district: values.district,
          },
          documents: {
            gst: values.gstNo,
            UdyamRegd: values.udyamRegistration,
            iso: values.isoCerificate,
            pan: values.panNo,
            inc: values.incNo,
            shopActNo: values.shopAct?.toUpperCase(),
          },
          socials: {
            website: values.companyWebsite,
          },
        };
        dispatch(registerCompanyRequest(new_values));
      } catch (error) {
        dispatch(resetBackdrop());
      }
    },
  });

  React.useEffect(() => {
    if (verifyData === null) {
      navigate("/");
    }
  }, []);

  return <CompanyRegistrationComponent formik={formik} ibaValue={setIba} />;
};
export default CompanyRegistration;
