import { Route, Navigate } from "react-router-dom";
import AboutUs from "./Components/AboutUs/AboutUs";
import FeaturesSection from "./Components/FeaturesSection/FeaturesSection";
import Pricing from "./Components/Pricing";
import PrivacyPolicy from "./Components/PrivacyPolicy/PrivacyPolicy";
import TearmsOfService from "./Components/TearmsOfService/TearmsOfService";
import Checkout from "./Components/UIComponents/Checkout";
import Layout from "./Layout";
import CompanyRegistration from "./Pages/CompanyRegistration";
import Contact from "./Pages/Contact";
import Home from "./Pages/Home";
import Services from "./Pages/Services";
import KnowledgeBasePage from "./Pages/Knowledge";
import RefundPolicy from "./Pages/RefundPolicy";
import PaymentSuccess from "./Pages/PaymentSuccess";
import PaymentFailed from "./Pages/PaymentFailed";
import AccountDelete from "./Pages/AccountDelete";

export const routes = (
  <Route path="/">
    <Route
      index
      element={
        <Layout>
          <Home />
        </Layout>
      }
    />
    <Route
      path="/services"
      element={
        <Layout>
          <Services />
        </Layout>
      }
    />
    <Route
      path="/about-us"
      element={
        <Layout>
          <AboutUs />
        </Layout>
      }
    />
    <Route
      path="/privacy-policy"
      element={
        <Layout>
          <PrivacyPolicy />
        </Layout>
      }
    />
    <Route
      path="/terms-of-services"
      element={
        <Layout>
          <TearmsOfService />
        </Layout>
      }
    />
    <Route
      path="/features"
      element={
        <Layout>
          <FeaturesSection />
        </Layout>
      }
    />
    <Route
      path="/pricing"
      element={
        <Layout>
          <Pricing />
        </Layout>
      }
    />
    <Route
      path="/contact"
      element={
        <Layout>
          <Contact />
        </Layout>
      }
    />
    <Route
      path="/checkout"
      element={
        <Layout>
          <Checkout />
        </Layout>
      }
    />
    <Route
      path="/company-registration"
      element={
        <Layout>
          <CompanyRegistration />
        </Layout>
      }
    />
    <Route
      path="/knowledgeBase-page"
      element={
        <Layout>
          <KnowledgeBasePage />
        </Layout>
      }
    />
    <Route
      path="/payment-success"
      element={
        <Layout>
          <PaymentSuccess />
        </Layout>
      }
    />
    <Route
      path="/payment-failed"
      element={
        <Layout>
          <PaymentFailed />
        </Layout>
      }
    />
    <Route
      path="/delete-account-request"
      element={
        <Layout>
          <AccountDelete />
        </Layout>
      }
    />
    <Route
      path="/refund-policy"
      element={
        <Layout>
          <RefundPolicy />
        </Layout>
      }
    />
    <Route path="*" element={<Navigate to="/" />} />
  </Route>
  // https://epackbook.in/
);
