import { Box, Container, Typography } from "@mui/material";
import ComponentSubHeader from "../UIComponents/ComponentSubHeader";
import { Link } from "react-router-dom";
import React from "react";

const RefundPolicy = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);

    }, []);

    return (
        <>
            <ComponentSubHeader heading={'Refund Policy'} current={'Refund Policy'} />

            <Box>
                <Container>
                    <Typography variant="h4" sx={{ marginBottom: "20px" }}>
                        Refund policy ePackBook Subscriptions?

                    </Typography>
                    <p style={{ marginBottom: "20px", textAlign: "justify", lineHeight: "1.5" }}><strong style={{ marginBottom: "15px" }}>ePackBook's Customer Satisfaction Policy</strong><br />At ePackBook we strive to create quality software that you enjoy using for your business or professional life. You have a number of choices and we appreciate you giving us your business. Thank You. We have created this policy that details what we will do should we fail to meet your expectations.
                    </p>
                    <p style={{ marginBottom: "20px", textAlign: "justify", lineHeight: "1.5" }}> <strong>Monthly Subscriptions.</strong> If at any time during your <strong>first 3 Days</strong> using our service you are dissatisfied, please contact us. We will do our best to address your issue, provide a work around or give a timeline for a solution that will meet your needs. If you are not satisfied, we will gladly offer you a <strong>FULL REFUND</strong> for your purchase, and downgrade your account to the free plan for that service.</p>
                    <p style={{ marginBottom: "20px", textAlign: "justify", lineHeight: "1.5" }}><strong>Annual Subscriptions</strong> . ePackBook doesn't force you into an annual subscription as a condition to use our services. We prefer to give you the flexibility to choose. In exchange for you signing up for an annual up-front commitment, we offer you a significant discount over the already-low monthly subscription cost. If at any time during your <strong>first 7 days</strong> using our service you are dissatisfied, please contact us. We will do our best to address your issue, provide a work around or give a timeline for a solution that will meet your needs. If you are not satisfied, we will gladly offer you a <strong>FULL REFUND</strong> for your purchase, and downgrade your account to the free plan.</p>
                    <p style={{ marginBottom: "20px", textAlign: "justify", lineHeight: "1.5" }}>
                        We want you to be happy with our service throughout your entire contract, not only the first 15 days (in case of monthly subscription) and the <strong>first 7 days</strong> (in case of annual subscription). So we go beyond that. If at any time during your contract we remove, break or discontinue functionality that was available at the time you signed up for our services , we ask you to notify us immediately.
                    </p>
                    <p style={{ marginBottom: "20px", textAlign: "justify", lineHeight: "1.5" }}>
                        <strong> Partial Downgrade of Annual Subscription</strong>. There may be situations where you wish to partially downgrade your annual subscription. Partial downgrade occurs if, during your annual subscription period, you reduce the number of user licenses, downgrade the edition of our services, downgrade your paid support plan or remove an add-on that you had previously purchased. In case of partial downgrade of annual subscription, you will be provided credits in the form of extension of subscription for the active licenses. The discounts that were applied will be excluded for calculating the quantum of credits, i.e., the value for the un-used license = total amount paid - (monthly list price x number of months used).
                    </p>
                    <p style={{ marginBottom: "20px", textAlign: "justify", lineHeight: "1.5" }}>
                        <strong>Auto-Renewal.</strong> For your convenience, your monthly and yearly subscriptions will auto-renew until you cancel the service. Every time before your subscription auto-renews, we will send a mail specifying the amount that will be charged to your credit card. Similarly, after each renewal we will send you a receipt via e-mail specifying the amount that has been deducted together with the next renewal date and the next renewal amount.
                    </p>
                    <p style={{ marginBottom: "20px", textAlign: "justify", lineHeight: "1.5" }}>
                        We know that sometimes customers forget to cancel an account they no longer want until it has already been charged. That's why you can cancel your monthly/annual subscription <strong>even five business days after your renewal date</strong>, and we will still process your cancellation and give you a FULL REFUND .
                        For questions, please e-mail  <Link to={"/ info@epackbook.in"} style={{ fontWeight: "700", color: "#000" }}>
                            info@epackbook.in
                        </Link>
                    </p>
                    <p style={{ marginBottom: "20px", textAlign: "justify", lineHeight: "1.5" }}>
                        <strong> Exception to our Refund Policy</strong><br />
                        Please note that we will not entertain a request for refund (FULL or PRO-RATED) when we have suspended or terminated your access to ePackBook Services due to a violation of our
                        <Link to={"/terms-of-services"} style={{ fontWeight: "700", color: "#000" }}>
                            Terms of Service
                        </Link>

                    </p>

                </Container>
            </Box >

        </>
    )
};

export default RefundPolicy;