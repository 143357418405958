import { Container, Grid, Typography } from "@mui/material";
import React from "react";
import "./Style.css";


const DummyData = [
    {
        text: 'Today Follow ups in No.'
    },
    {
        text: 'Today Shiftings in No.'
    },
    {
        text: 'Approved Enuiries in No.'
    },
    {
        text: 'Today Surveys in No.'
    },
    {
        text: 'Cancelled enquiries in No.'
    },
]

const UserFriendlyDashboardView = (props) => {

    return (
        <Container className="userFriendlyDashboard">
            <Grid container>
                <Grid item md={12}>
                    <Typography variant="h4" style={{ marginBottom: 20, fontSize: 26 }}>
                        Manage Your Daily Operations from User Friendly Dashboard
                    </Typography>
                    <Typography variant="body1" style={{ marginBottom: 20, textAlign: "justify" }}>
                        ePackBook Dashboard is a user friendly interface that helps you to analyze your business economical situation. Easy Navigation to operate ePckBook software. Enquiry Status (New/Under Process/Approved/Cancelled) with information. Track each and every steps of enquiry.
                    </Typography>
                </Grid>
                <Grid item md={12}>
                    <Grid container spacing={2}>
                        <Grid item md={4}>
                            <Typography variant="body1" style={{ marginBottom: 15, fontWeight: 600 }}>
                                Statistic view of Enquiry
                            </Typography>
                            <ul style={{ listStyle: "none" }}>
                                {DummyData.map((_, i) => (
                                    <li style={{ marginBottom: 20 }} key={i}>{_.text}</li>
                                ))
                                }

                            </ul>
                        </Grid>
                        <Grid item md={4}>
                            <Typography variant="body1" style={{ marginBottom: 15, fontWeight: 600 }}>
                                Upcoming Surveys & Follow Ups
                            </Typography>
                            <p style={{ marginBottom: 20 }}>Upcoming surveys details – Name, Mobile No., Shifting From, Survey date & Time.</p>
                            <p>Upcoming Follow Ups – Name, Mobile No., Last communication, Oral Quotations info etc.</p>
                        </Grid>
                        <Grid item md={4}>
                            <Typography variant="body1" style={{ marginBottom: 15, fontWeight: 600 }}>
                                Today Shifting & Enquiry Status
                            </Typography>
                            <p style={{ marginBottom: 20 }}>Today Shifting – Name, Mobile No., Carrier Vehicles,  Shifting From, Shifting To info.
                            </p>
                            <p>Enquiry Status – New (Name, Mobile No., Shifting From, Shifting To info. ) Under Process (Name, Mobile No., Shifting From, Last Communication, Quotation comment info.)
                            </p>
                        </Grid>
                    </Grid>

                </Grid>
                {/*<Typography variant="h4" style={{ marginTop: 80 }}>
                //     ePackBook Dashboard Look
                // </Typography> */}
            </Grid>
        </Container>
    );
}

export default UserFriendlyDashboardView;