import { Box } from "@mui/material";
import react from "react";
import "./Style.css";


const InvoiceModuleView = () => {

    return (
        <Box className="invoiceModule">
            <h1>InvoiceModuleView</h1>
        </Box>
    )
};

export default InvoiceModuleView;