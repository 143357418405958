import React, { useEffect } from "react";
import SectionOne from "../Components/Features/sectionOne";
import SectionTwo from "../Components/Features/SectionTwo";
import SectionThree from "../Components/Features/SectionThree";
import SectionFour from "../Components/Features/SectionFour";

function Features(props) {
  return (
    <main>
      <section>
        <SectionOne />
      </section>
      <section>
        <SectionTwo />
      </section>
      <section>
        <SectionFour />
      </section>
    </main>
  );
}

export default Features;
