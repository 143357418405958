import { all, fork } from "redux-saga/effects";
import { watcherSendEmailSaga } from "./FreeAccount.Saga";
import { watcherRegisterCompanySaga } from "./RegisterCompany.Saga";
import { watcherRegisterForDemoSaga } from "./RegisterForDemo.Saga";

export function* rootSaga() {
  yield all([
    fork(watcherSendEmailSaga),
    fork(watcherRegisterCompanySaga),
    fork(watcherRegisterForDemoSaga),
  ]);
}
