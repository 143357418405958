
    const PAN_CARD = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/
    const INDIAN_MOBILE = /^[6-9]\d{9}$/
    const EMAIL_ADDRESS = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const NAMES = /[^a-zA-Z\s]/gm
    const COMPANY_NAME = /[^0-9a-zA-Z.\s]/gm
    const PINCODE = /^[1-9]\d{5}$/
    const PHONE = /^[0-9]{11}$/
    const URL = /^(https?:\/\/)([\w-]+\.)+[\w]+(\/[\w-./?%&=]*)?$/
    export const regax =  {
        PAN_CARD,
        INDIAN_MOBILE,
        EMAIL_ADDRESS,
        NAMES,
        PINCODE,
        PHONE,
        URL,
        COMPANY_NAME
    }
