import { useLocation } from "react-router-dom";
import PageHeader from "../../Components/PageHeader";
import React from "react";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { MdKeyboardArrowDown } from "react-icons/md";
import ComponentSubHeader from "../UIComponents/ComponentSubHeader";

const KnowledgePage = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const AccordianData = [
    {
      title: " What is ePackBook?",
      summary: "ggfghfghjkertfgyhj",
      details:
        "  Welcome to ePackbook 2023: The Best Moving Company CRM Software!",
    },
    {
      title: " How can ePackBook help us manage my day to day operations?",
      details:
        "  ePackBook enables you to create and manage all the day-to-day works online like - Lead Management, Quotation Creation, Docs management, Shifting Date Management, Creating and sharing Money Receipt, etc. ",
    },
    {
      title: " Can we manage all my leads under one platform? ",
      details:
        "  Yes, ePackBook enables you to manage and edit all your leads in one single dashboard and eliminates unwanted business loss.",
    },
    {
      title: " Can I fix a Shifting Date on this platform? ",
      details:
        " Yes, not only you can fix the Shifting date, you can also alter the date if required from the enquiry list or order list itself.",
    },
    {
      title:
        " Can packers and movers assign employees in one account or do I have to purchase different accounts? ",
      details:
        " ePackBook enables you to create different profiles for employees without charging a single penny.",
    },
    {
      title: " How much do you charge for one account? ",
      details:
        "  Currently, during the beta version we are not charging a single penny for using our ePackBook CRM. It’s absolutely free.",
    },
    {
      title:
        " Do I have to carry a Laptop each and every time to use an ePackBook? ",
      details:
        "  Certainly not, now you can use mobile to create packers and movers documents because it has a mobile friendly user interface. We are working to release an app for you to make it more accessible.",
    },
  ];
  const AccordianData2 = [
    {
      title:
        "Do you have any knowledge base videos to make it easy to understand?",
      details:
        " Yes, we do have a YouTube channel just to give you a demo of how things work on our CRM. Please click here to watch the videos. https://www.youtube.com/@ePackBook ",
    },
    {
      title: " How to connect with you if I need any help? ",
      details:
        "  You can always write to us at info@epackbook.in or directly reach out to us on +91  9355771100. ",
    },
  ];
  const AccordianData3 = [
    {
      title: " Can I create a Quotation for Customers Online?",
      details:
        " ePackBook enables Packers and movers to create quotations for their customers online and save the data for future records. Create Quotations, Preview Quotations, Download Quotations, Edit Quotations.",
    },
    {
      title: " Can I create a Money Receipt for Customers Online?",
      details:
        " ePackBook enables packers and movers companies to create Money Receipts for their customers. Can create Advance Payment, Part Payment & Final Payment etc. Create Money Receipt, Preview Money Receipt, Download Money Receipt, Edit Money Receipt.",
    },
    {
      title:
        " Can Packers and Movers create an Invoice by using ePackBook for their Customers Online?",
      details:
        "  Sure, you can create Invoices online for your customers and save both time & paper. Create Invoice, Preview Invoice, Download Invoice, Edit Invoice.",
    },
    {
      title: "Can I create Car Conditions Online?",
      details:
        " Yes, you can create Car Conditions online using ePackBook - The Packers & Movers CRM. Most importantly, no one can change the data rather than who has access once it has been created on the dashboard. Create, Preview, Download and Edit.",
    },
    {
      title: "Which Documents do I need to create an account on ePackBook? ",
      details:
        " To create a free account on ePackBook, you need basic documents like PAN, GST and other business-related documents. Though if you are not registered under GST, you can skip that too.",
    },
  ];
  const AccordianData4 = [
    {
      title: " How can i delete my account data?",
      details:
        " Submit your delete account request by visiting given link https://epackbook.in/delete-account-request we will remove your account data after review your request within 7 working days.",
      action: "Agree",
    },
  ];

  const DATA = [
    {
      title: "General",
      link: "www.epackbook.in",
      totalArticles: 10,
      questions: AccordianData,
    },
    {
      title: "Documentation",
      link: "www.epackbook.in",
      totalArticles: 10,
      questions: AccordianData3,
    },
    {
      title: "Support",
      link: "www.epackbook.in",
      totalArticles: 10,
      questions: AccordianData2,
    },
    {
      title: "Data Security",
      link: "www.epackbook.in",
      totalArticles: 10,
      questions: AccordianData4,
    },
  ];

  const location = useLocation();
  const { canGoBack = false } = location.state || {};
  return (
    <>
      <ComponentSubHeader
        heading={"Knowledge Base"}
        current={"Knowledge Base"}
        isSearchAble
      />
      <Box>
        <Container>
          <Box>
            <Grid container spacing={2}>
              {DATA.map((item, index) => (
                <Grid item xs={12} lg={6}>
                  <Card
                    key={index}
                    variant="outlined"
                    sx={{ bgcolor: "transparent" }}
                  >
                    <CardHeader title={item.title} />
                    <CardContent>
                      {item.questions.map((_, i) => (
                        <Accordion key={i} variant="outlined">
                          <AccordionSummary
                            expandIcon={<MdKeyboardArrowDown />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography>{_.title}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>{_.details}</Typography>
                          </AccordionDetails>
                          <AccordionActions>
                            <Button>{_.action}</Button>
                          </AccordionActions>
                        </Accordion>
                      ))}
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default KnowledgePage;
