import { Box, Divider, Grid, Typography } from "@mui/material";
import { Container } from "@mui/system";
import icon from "../../Assets/icons/001-start-up.png";
import reportIcon from "../../Assets/Images/powerfulReports.svg";
import emailIcon from "../../Assets/Images/automaticEmails.svg";
import MobileIcon from "../../Assets/Images/mobileApp.svg";
import RemienderIcon from "../../Assets/Images/quickReminder.svg";
import ReferIcon from "../../Assets/Images/multiTemplate.svg";
import BranchesIcon from "../../Assets/Images/multipalBranches.svg";
import "./style.css";

function SectionTwo(props) {
  return (
    <Box className="features-section-two-container">
      <Container>
        <Divider sx={{ mb: 5 }}>
          <h2>Premium Features</h2>
        </Divider>

        <Grid container spacing={{ xs: 1, sm: 2 }}>
          {props.servicesIcon.map((d, i) => (
            <Grid key={i} item xs={12} sm={6} lg={2}>
              <Box sx={{ textAlign: "center" }}>
                <Box className="icon-container">
                  <Box
                    className="icon-container-round"
                    style={{
                      height: 100,
                      width: 100,
                      borderRadius: "50%",
                      border: "5px solid #5A5B5C",
                      margin: "auto",
                      marginBottom: 10,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={d.image}
                      style={{ height: "90px", objectFit: "contain" }}
                    />
                  </Box>
                  <Typography variant="h6">{d.text}</Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

SectionTwo.defaultProps = {
  servicesIcon: [
    {
      image: reportIcon,
      text: "Powerfull Reports",
    },
    {
      image: emailIcon,
      text: "Automatic Emails",
    },
    {
      image: MobileIcon,
      text: "Mobile App",
    },
    {
      image: RemienderIcon,
      text: "Quick Reminders",
    },
    {
      image: ReferIcon,
      text: "Multi Template",
    },
    {
      image: BranchesIcon,
      text: "Multi Branches",
    },
  ],
};

export default SectionTwo;
