import React, { useState } from "react";
import { IconButton } from "@mui/material";
import { IoClose } from "react-icons/io5";
import pdfImg from "../../../Assets/Images/pdfimage.png";
import "./style.css";

function ImagePicker({ buttonName, id, formik, errorMsg, fileType }) {
  const [file, setFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [pdfname, setPdfname] = useState("");

  function handleImageChange(e) {
    if (fileType === "application/pdf") {
      const files = e.target.files[0];
      formik.setFieldValue(id, e.target.files[0]);
      setPdfname(files.name);
      setFile(URL.createObjectURL(files).replace("blob:", ""));
    }
    if (fileType !== "application/pdf") {
      setFile(e.target.files[0]);
      setImageUrl(URL.createObjectURL(e.target.files[0]));
      formik.setFieldValue(id, e.target.files[0]);
    }
  }

  const handleClose = () => {
    setFile(null);
    formik.setFieldValue(id, "");
  };

  const RenderJsx = () => {
    // eslint-disable-next-line no-lone-blocks
    return (
      <div>
        {fileType === "application/pdf" ? (
          <div style={{ minWidth: 300 }}>
            <img
              src={pdfImg}
              alt="pdflogo"
              style={{ width: 100, height: 100 }}
            />
            <IconButton className="closeBtn" onClick={handleClose}>
              <IoClose color="white" size={14} />
            </IconButton>
            <div>
              <small style={{ fontSize: 10 }}>{pdfname && pdfname}</small>
            </div>
          </div>
        ) : (
          <div className="imagePreview">
            <img src={imageUrl} alt="Uploaded" style={{ maxWidth: "100%" }} />
            <IconButton className="closeBtn" onClick={handleClose}>
              <IoClose color="white" size={14} />
            </IconButton>
            <div style={{ minWidth: 300, marginBottom: 10 }}>
              <small style={{ fontSize: 10, marginTop: 10 }}>
                {file.name && file.name}
              </small>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="imagePickerLayout">
        <input
          accept={fileType}
          style={{ display: "none" }}
          id={id}
          type="file"
          onChange={handleImageChange}
        />
        {file ? (
          <RenderJsx />
        ) : (
          <label htmlFor={id}>
            <div
              className="imagepickerTemplate"
              style={{
                border: `1px dashed ${errorMsg ? "#D32F2F" : "black"}`,
                color: `${errorMsg ? "#D32F2F" : "black"}`,
              }}
            >
              {buttonName}
              {fileType === "application/pdf" && (
                <small style={{ fontSize: 8, marginTop: 10 }}>
                  only pdf awllowed
                </small>
              )}
            </div>
          </label>
        )}
      </div>
      <small style={{ color: "#D32F2F", fontSize: "12px", marginLeft: 12 }}>
        {errorMsg && errorMsg}
      </small>
    </>
  );
}

export default ImagePicker;
