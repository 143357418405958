import { Grid, Typography, Box } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import "./Style.css";

const DummyData = [
    {
        text: '1.Lead generation and tracking: The ability to capture and manage inquiries from potential customers.'
    },
    {
        text: '2.Customer database: A database to store and manage customer information, including contact details, inquiry details, and previous interactions.'
    },
    {
        text: '3. Inquiry categorization: A system to categorize and prioritize inquiries based on specific criteria.'
    },
    {
        text: "4. Automated follow-up: The ability to set up automated email or SMS follow-up sequences for inquiries that haven't been addressed."
    },
    {
        text: '5. Quotation management: The ability to create and manage quotes for potential customers, including the ability to compare quotes from different packers and movers.'
    },
    {
        text: "6. Booking management: The ability to manage bookings and scheduling for confirmed moves."
    },
    {
        text: "7. Customer communication: The ability to communicate with customers through multiple channels, including email,SMS,and phone."
    },
    {
        text: '8. Reporting and analytics: The ability to generate reports and analytics on inquiries, quotes, bookings, and customer interactions to help optimize the process.'
    },
    {
        text: '9. Integration with other systems: The ability to integrate with other systems, such as accounting, customer relationship management (CRM), and transportation management systems (TMS).'
    },
]


const EnquiryManagementView = () => {

    return (
        <>
            <Container className="enquiryManagement">
                <Grid container>
                    <Grid item sx={12}>
                        <Typography variant="h4" style={{ marginBottom: 30, fontSize: 26 }}>
                            ePackBook - Enquiry Management - Track enquiries & Go ahead
                        </Typography>
                        <p style={{ marginBottom: 30 }}>Easy ways to manage all packers and movers enquiries on single platforms</p>

                        <ol>
                            {DummyData.map((_, i) => {
                                const txtArr = _.text.split(":");
                                return (

                                    <li key={i} style={{ marginBottom: 30, listStyle: 'none', lineHeight: 1.5, textAlign: "justify" }}><span style={{ fontWeight: '600' }}>{txtArr[0]} &#58;</span>{txtArr[1]} </li>
                                )
                            })
                            }
                        </ol>

                    </Grid>
                </Grid>
            </Container>
        </>
    )

};

export default EnquiryManagementView; 