import { Container, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import react from "react";
import "./Style.css";


const DummyData = [
    {
        text: "1. Automatic Updates: Whenever a payment is made or a referral sent, ePackBook will automatically add the event to your reports"
    },
    {
        text: "2.  Sort by Date: Make finding the report you need simple by choosing to look only at reports made within a certain timeframe"
    },
    {
        text: "3. Sort by Type: ePackBook automatically sorts reports based on what was reported, making it easy to find what you need"
    },
    {
        text: "4.Download Reports: Download any and all reports as PDFs and Excel compatible CSVs."
    },

]

const PowerfulReportsView = () => {

    return (
        <>
            <Container className="powerfulReports">
                <Grid Container>
                    <Grid item sx={12}>
                        <Typography variant="h4" style={{ marginBottom: 30, fontSize: 26 }}>
                            Powerful Reports
                        </Typography>
                        <p style={{ marginBottom: 30 }}>ePackBook Reports allow you to generate custom reports by date range for many operation and transactional types of data including Payments, Referrals, Claims, and even Commissions paid.
                        </p>

                        <ol>
                            {DummyData.map((_, i) => {
                                const txtArr = _.text.split(":");
                                return (

                                    <li key={i} style={{ marginBottom: 30, listStyle: 'none', lineHeight: 1.5, textAlign: "justify" }}><span style={{ fontWeight: '600' }}>{txtArr[0]} &#58;</span>{txtArr[1]} </li>
                                )
                            })
                            }
                        </ol>

                    </Grid>
                </Grid>
            </Container>
        </>
    )
};

export default PowerfulReportsView;
