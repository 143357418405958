import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { padding } from "@mui/system";
import React from "react";
import Image from "../../Assets/Images/enquiryManagement.svg";
import { Link } from 'react-router-dom';

function SectionOne(props) {

  const getIndex = (header) => {
    let index = 0;
    if (header === 'ENQUIRY MANAGEMENT') {
      index = 1;
    } else if (header === 'SHIFTING MANAGEMENT') {
      index = 4;
    } else if (header === 'CUSTOMER MANAGEMENT') {
      index = 9;
    }
    return index;
  }
  return (
    <Box className="features-section-one-container">
      <Container sx={{ my: 2 }}>
        <Divider sx={{ my: 2 }}>
          <h2>Features</h2>
        </Divider>
        <Grid container spacing={{ xs: 1, sm: 2 }}>
          {props.data.map((_, index) => (
            <Grid item xs={12} sm={6} lg={3} key={index} >
              <Card sx={{ minHeight: "460px", color: "#fff" }} style={{ backgroundColor: _.bgcolor }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: '8px' }}>
                  <CardMedia
                    sx={{ height: 180, width: 250 }}
                    image={_.image.uri}
                    title={_.image.title}
                  />
                </Box>
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div" fontSize={18}>
                    {_.header}
                  </Typography>
                  {typeof _.content === "string" ? (
                    <Typography variant="body2" sx={{ lineHeight: 1.5 }}>{_.content}</Typography>
                  ) : (
                    _.content
                  )}
                </CardContent>
                <CardActions>
                  <Button component={Link} size="small" to={'/features'} state={{ index: getIndex(_.header) }}>
                    Read More...
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

SectionOne.defaultProps = {
  data: [
    {
      bgcolor: "#86d9ab",
      image: {
        uri: "/static/images/enquiryManagement.svg",
        title: "image title",
      },
      header: "ENQUIRY MANAGEMENT",
      content: (
        <ul style={{ marginLeft: '15px' }}>
          <li>Add Enquiry & Process</li>
          <li>Update Enquiry info on Call</li>
          <li>Set Follow Ups with Comment</li>
          <li>Set Survey Date & Time</li>
          <li>Create & Share Quotation</li>
        </ul>
      ),
      redirectionUri: "https://google.com",
    },
    {
      bgcolor: "#6ec7e0",
      image: {
        uri: "/static/images/paperLessDocuments.svg",
        title: "image title",
      },
      header: "PAPERLESS DOCUMENTS",
      content:
        (
          <ul style={{ marginLeft: '15px' }}>
            <li>Create & Share Quotations</li>
            <li>Create & Share Bilty</li>
            <li>Create & Share Invoice</li>
            <li>Create & Share Money Receipt</li>
            <li>Create & Share Car Condition</li>
          </ul>
        ),
      redirectionUri: "https://google.com",
    },
    {
      bgcolor: "#cc90cc",
      image: {
        uri: "/static/images/shiftingManagement.svg",
        title: "image title",
      },
      header: "SHIFTING MANAGEMENT",
      content:
        (
          <ul style={{ marginLeft: '15px' }}>
            <li> Shifting Status Update</li>
            <li>Add New Shifting Details</li>
            <li>Create Shifting Documents </li>
            <li> Comments - Shifting Requirements</li>
            <li> Assign Shifting Manager</li>
          </ul>
        ),
      redirectionUri: "https://google.com",
    },
    {
      bgcolor: "#e4c4a1",
      image: {
        uri: "/static/images/customerManagement.svg",
        title: "image title",
      },
      header: "CUSTOMER MANAGEMENT",
      content:
        (
          <ul style={{ marginLeft: '15px' }}>
            <li>  Automatic Customer Update</li>
            <li>Past Shifting Details</li>
            <li>Powerful Search Options</li>
            <li>Unique Customer ID</li>
            <li> Unique Shifting ID</li>
          </ul>
        ),

      redirectionUri: "https://google.com",
    },
  ],
};

export default SectionOne;
