import { Grid, List, ListItem } from "@mui/material";
import { Box, Container } from "@mui/system";
import "./style.css";
import { HiBriefcase, HiMail, HiUsers, HiIdentification, HiDesktopComputer, HiClipboardList } from "react-icons/hi";

import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";




const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

function SectionFour(props) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Box sx={{ marginTop: 3 }}>
      <Container>
        <Grid container spacing={2}>
          {props.data.map((_, i) => (
            <Grid item xs={12} sm={6} lg={4} key={`${i}`}>
              <Card sx={{
                boxShadow: "none", minHeight: "300px", background
                  : _.color
              }}>
                <CardHeader avatar={_.icon} title={_.heading} className='mycard' />
                <CardContent>
                  <ul style={{ marginLeft: 20, lineHeight: 1.5 }}>
                    {_.features.map((text, i) => (
                      <li key={i}>
                        <Typography variant="body1" color="text.secondary" key={`${i}`}>
                          {text}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
SectionFour.defaultProps = {
  data: [
    {
      icon: <HiBriefcase />,
      heading: "Shifting Management",
      features: ['You can handle Approved Shifting Management process in a systematic manner.',
        'One click status change from enquiry to approved order',
        'Add shifting date, Truck load, Token amount, Pickup & Drop Address, Consignment No. etc.'

      ]
    },
    {
      icon: <HiMail />,
      // color:"#6ec7e0",
      heading: "Mail Communication",
      features: ['System generated mails will send to clients',
        'Unlimited mails to unlimited clients',
        'Thanks giving mails (after call to client)',
        'Informatics mails (Survey Date and Time with surveyor Details )',
        'Informatics mails (booking id with confirmation)'


      ]
    },
    {
      icon: <HiUsers />,
      // color:"#e4c4a1",
      heading: "User Friendly Dashboard",
      features: ['Today follow ups Details',
        'Today Approved Shifting Details',
        'Upcoming Survey Details',
        'Enquiry Progress Details',
        'Recent Orders Details',
        'Recent Delivered Orders Details',

      ]
    },
    {
      icon: <HiIdentification />,
      // color:"#e4c4a1",
      heading: "Process Tracking",
      features: ['Each enquiry is tracked by calls, mails, status, comments etc.',
        'End to End process (enquiry to delivered goods)',
        'Auto Notifications Enabled',]
    },
    {
      icon: <HiDesktopComputer />,
      // color:"#cc90cc",
      heading: "Multi Devices",
      features: ['ePackBook can be used on all available devices.',
        'Computers – Mobiles – Tabs – Android & App Store',
      ]
    },
    {
      icon: <HiClipboardList />,
      // color:"#86d9ab",
      heading: "Powerful Reports",
      features: ['Download Today’s Task',
        'Download all enquiries, Approved Shiftings, All Customers',
        'Download Shifting types – Local – Domestic – International',
        'Duration Specified Reports Download',
      ]
    },
  ],
};

export default SectionFour;
