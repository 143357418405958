import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  requesting: false,
  success: false,
  fail: false,
  data: null,
};

const RegisterCompanySlice = createSlice({
  name: "RegisterCompanySlice",
  initialState: initialState,
  reducers: {
    registerCompanyRequest: (state, action) => {
      state.requesting = true;
    },
    registerCompanySuccess: (state, action) => {
      state.requesting = false;
      state.success = true;
      state.data = action.payload;
    },
    registerCompanyFail: (state, action) => {
      state.requesting = false;
      state.fail = true;
      state.data = action.payload;
    },
    reset: (state, action) => {
      state.requesting = false;
      state.success = false;
      state.fail = false;
      state.data = null;
    },
  },
});
export const {
  registerCompanyRequest,
  registerCompanySuccess,
  registerCompanyFail,
  reset,
} = RegisterCompanySlice.actions;

export default RegisterCompanySlice.reducer;
