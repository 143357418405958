import { Container, Typography, Box } from "@mui/material";
import React from "react";
import ComponentSubHeader from "../UIComponents/ComponentSubHeader";
import './Style.css';


const DummyData = [
    {
        text: 'You must provide a valid email address and any other information needed in order to complete the signup process.'
    },
    {
        text: 'You are responsible for keeping your password secure. ePackBook cannot and will not be liable for any loss or damage from your failure to maintain the security of your account and password.'
    },
    {
        text: 'You may not use the service for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws) as well as the Constitution of India and jurisdiction Gurugram (HR).'
    },
    {
        text: 'You are responsible for all activity and content (data, graphics, photos, links) that is uploaded under your ePackBook account.'
    },
    {
        text: 'You must not transmit any worms or viruses or any code of a destructive nature.'
    },
    {
        text: 'A breach or violation of any of the Account Terms as determined in the sole discretion of ePackBook will result in an immediate termination of your services.'
    },
    {
        text: 'You must be 18 years or older to use this Service.'
    },
]

const DummyDataTwo = [
    {
        text: 'We reserve the right to modify or terminate the Service for any reason, without notice at any time.'
    },
    {
        text: 'We reserve the right to refuse service to anyone for any reason at any time.'
    },
    {
        text: 'Your use of the Service is at your sole risk. The Service is provided on an “as is and “as available" basis without any warranty or condition, express, implied or statutory.'
    },
    {
        text: 'ePackBook does not warrant that the service will be uninterrupted, timely, secure, or error-free.'
    },
    {
        text: 'ePackBook does not warrant that the results that may be obtained from the use of the service will be accurate or reliable.'
    },
    {
        text: 'You understand that your Content (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit Card information is always encrypted during transfer over networks.'
    },
    {
        text: 'We may, but have no obligation to, remove Content and Accounts containing Content that we determine in our sole discretion are unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable or violates any party’s intellectual property or these Terms of Service.'
    },
    {
        text: 'You expressly understand and agree that ePackBook shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses resulting from the use of or inability to use the service.'
    },
    {
        text: 'Technical support is only provided to paying account holders and is only available via phone, email or the support forum.'
    },
    {
        text: 'You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the The ePackBook system or Service, use of the Service, or access to the Service without the express written permission by ePackBook.'
    },
    {
        text: 'Verbal or written abuse of any kind (including threats of abuse or retribution) of any ePackBook customer, ePackBook employee, member, or officer will result in immediate account termination.'
    },
    {
        text: 'We do not claim any intellectual property rights over the material you provide to the ePackBook service. All material you upload remains yours. You can remove your ePackBook account at any time by deleting your account. This will also remove all content you have stored on the Service.'
    },
    {
        text: 'The failure of ePackBook to exercise or enforce any right or provision of the Terms of Service shall not constitute a waiver of such right or provision. The Terms of Service constitutes the entire agreement between you and ePackBook and govern your use of the Service, superseding any prior agreements between you and ePackBook (including, but not limited to, any prior versions of the Terms of Service).'
    },
    {
        text: 'You retain ownership over all content that you submit to a ePackBook account, however, by making your account public, you agree to allow others to view your content.'
    },
    {
        text: 'ePackBook does not pre-screen Content and it is in their sole discretion to refuse or remove any Content that is available via the Service.'
    },
    {
        text: 'Questions about the Terms of Service should be sent to support@epackbook.com'
    },
]

const DummyDataThree = [
    {
        text: 'A valid credit card/Debit Card/Net Banking is required for paying accounts. Free accounts are not required to provide a credit card number.'
    },
    {
        text: 'The Service will be billed on yearly basis. There are no refunds for partial year of service, upgrade/downgrade refunds, or refunds for years unused with an open account.'
    },
    {
        text: 'When your billing period is over, ePackBook users will be automatically billed through their valid credit card on file. Users have five days to bring up and settle any issues with the billing.'
    },
    {
        text: 'Failure to make payment within 7 days of invoice will result in account access being restricted to the owner of the account.'
    },
]

const DummyDataFour = [
    {
        text: 'Prices for using ePackBook are subject to change upon 14 days notice from ePackBook. Such notice may be provided at any time by posting the changes to the ePackBook Site (ePackBook.com) or as an announcement in your ePackBook account.'
    },
    {
        text: 'ePackBook reserves the right time to time to modify or discontinue, the Service (or any part thereof) with or without notice.'
    },
    {
        text: 'ePackBook shall not be liable to you or to any third party for any modification, price change, suspension or discontinuance of the Service.'
    },

]



const TearmsOfService = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);

    }, []);

    return (
        <>
            <ComponentSubHeader heading={'Terms of Service'} current='Terms of Service' />
            <Box className="inneritem">
                <Container>
                    <p style={{ marginBottom: "20px", textAlign: "justify", lineHeight: "1.5" }}>By signing up for the ePackBook Service or any of the services of Essence web Technology, you are agreeing to be bound by the following terms and conditions (“Terms of Service"). Any new features or tools which are added to the current Service shall be also subject to the Terms of Service. You can review the most current version of the Terms of Service at any time. ePackBook reserves the right to update and change the Terms of Service by posting updates and changes to the ePackBook website. You are advised to check the Terms of Service from time to time for any changes that may impact you.</p>

                    <Typography style={{ marginBottom: "20px" }} variant="h5">
                        ACCOUNT TERMS
                    </Typography>
                    <ul style={{ marginBottom: "20px" }}>
                        {DummyData.map((d, i) => (
                            <li style={{ marginLeft: "15px", marginBottom: "10px", textAlign: "justify", lineHeight: "1.5" }} key={i}> {d.text}</li>
                        ))
                        }
                    </ul>
                    <Typography variant="h5">
                        GENERAL CONDITIONS
                    </Typography>
                    <p style={{ marginBottom: "20px", textAlign: "justify", lineHeight: "1.5" }}>You must read, agree with and accept all of the terms and conditions contained in this User Agreement and the Privacy Policy before you may become a member of ePackBook.</p>
                    <ul >
                        {DummyDataTwo.map((d, i) => (
                            <li style={{ marginLeft: "15px", marginBottom: "10px", textAlign: "justify", lineHeight: "1.5" }} key={i} >{d.text}</li>
                        ))
                        }

                    </ul>
                    <Typography variant="h5">
                        PAYMENT OF FEES
                    </Typography>
                    <ul style={{ marginBottom: "20px" }}>
                        {DummyDataThree.map((d, i) => (
                            <li style={{ marginLeft: "15px", marginBottom: "10px", textAlign: "justify", lineHeight: "1.5" }} key={i}> {d.text}</li>
                        ))
                        }
                    </ul>
                    <Typography variant="h5">
                        MODIFICATIONS TO THE SERVICE AND PRICES
                    </Typography>
                    <ul >
                        {DummyDataFour.map((d, i) => (
                            <li style={{ marginLeft: "15px", marginBottom: "10px", textAlign: "justify", lineHeight: "1.5" }} key={i}>{d.text}</li>
                        ))}
                    </ul>
                    <p style={{ marginBottom: "20px", textAlign: "justify", lineHeight: "1.5" }}>You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the The ePackBook system or Service, use of the Service, or access to the Service without the express written permission by ePackBook.  Any such activity shall be considered theft or vandalism, and a violation of our copyright and intellectual property.</p>
                </Container>
            </Box>
        </>
    )
};

export default TearmsOfService;