import { Box } from "@mui/system";
import react from "react";
import './Style.css';



const MoneyRecieptView = () => {

    return (
        <Box className="moneyReciept">
            <h1>MoneyRecieptView</h1>
        </Box>
    )

};

export default MoneyRecieptView;