import { Box } from "@mui/system";
import react from "react";
import "./Style.css"



const BranchManagementView =() => {

    return (
        <Box className="branchManagement">
        <h1>BranchManagementView</h1>
        </Box>
        
    )
};
export default BranchManagementView;