import {
  Grid,
  TextField,
  Typography,
  MenuItem,
  IconButton,
  Button,
  Box,
  Card,
} from "@mui/material";
import loginimg from "../../Assets/Images/login.png";
import { Container } from "@mui/system";
import "./style.css";
const AccountDelete = () => {
  return (
    <Container>
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <img style={{ width: "100%" }} src={loginimg} alt="image" />
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ mt: 3, p: 2 }}>
              <Typography variant="h5" mb={2}>
                {" "}
                Request to Delete Account
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    //   {...formik.getFieldProps("companyName")}
                    label="User ID"
                    variant="outlined"
                    size="small"
                    fullWidth
                    // error={!!formik.errors.companyNameErr}
                    // helperText={formik.errors.companyNameErr}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    //   {...formik.getFieldProps("companyName")}
                    label="Regd. Mobile No "
                    variant="outlined"
                    size="small"
                    fullWidth
                    // error={!!formik.errors.companyNameErr}
                    // helperText={formik.errors.companyNameErr}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    //   {...formik.getFieldProps("companyName")}
                    label="Reason for account Deletation"
                    variant="outlined"
                    size="small"
                    fullWidth
                    multiline
                    rows={3}
                    // error={!!formik.errors.companyNameErr}
                    // helperText={formik.errors.companyNameErr}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" fullWidth>
                    Send
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" mt={2}>
                    we will remove your account data after review your request
                    within 7 working days.
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default AccountDelete;
