import React from "react";
import Features from "./Features";
import Services from "./Services";
import Hero from "../Components/Home/Hero";

function Home() {
  return (
    <main>
      <Hero />
      <Services />
      <Features />
</main>
  );
}

export default Home;
