import { Button, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { Fragment, useState } from "react";
//import HeroImg from "../../../Assets/Images/heroImg.png";
import RegisterForDemo from "../../RegisterForDemo";
import "./style.css";
import { Link } from "react-router-dom";
import PlayStoreLogo from "../../../Assets/Images/play store logo black.png";
import VerificationModal from "../../UIComponents/VerificationModal";
const Hero = ({ demoFormik }) => {
  const [openRegisterForDemo, setOpenRegisterForDemo] = useState(false);
  const [openFreeAccountModal, setOpenFreeAccountModal] = useState(false);
  const handleClose = () => {
    setOpenRegisterForDemo(false);
  };
  const handleOpen = () => {
    setOpenRegisterForDemo(true);
  };

  const handleOpenFreeAccount = () => {
    setOpenFreeAccountModal(true);
  };
  const handleCloseFreeAccount = () => {
    setOpenFreeAccountModal(false);
  };
  return (
    <Fragment>
      <head>
        <title>ePackBook | Packers and Movers CRM Software in India</title>
        <meta
          name="description"
          content="ePackBook India’s No. 1 Packers and Movers CRM Software to Manage Relocation Business Online. ✔️Paperless ✔️EnquiryManagement ✔️CustomerManagement ✔️CloudBase"
        />
      </head>
      <Box className="hero">
        {/* <Box className="heroBg"></Box> */}
        <Box className="heroWrapperItem" align="center">
          <Box className="upperItem">
            <Typography
              variant="h4"
              style={{
                color: "#FFFFFF",
              }}
            >
              ePackBook Offer Relocation Companies to be Online
            </Typography>
            <Typography
              variant="h4"
              style={{
                color: "#FFFFFF",
              }}
            >
              To Manage Packers and Movers Business
            </Typography>
          </Box>
          <Box className="buttonItem">
            <Box className="registerForDemoBtn">
              <Link
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.epackbook"
                  )
                }
              >
                <Button variant="contained" size="large" >
                  Download app
                </Button>
              </Link>
            </Box>
            {/* <Box>
             <Link to={"#signin"}> 
              <Button
                variant="contained"
                size="large"
                onClick={handleOpenFreeAccount}
              >
                Get A Free Account
              </Button>
            </Link> 
          </Box> */}
          </Box>
          <Box className="lowertextSec1">
            <Typography
              variant="h4"
              style={{
                color: "#FFFFFF",
              }}
            >
              A Complete Solution For Movers and Packers Company
            </Typography>
          </Box>
          <Box className="lowertextSec2">
            <Typography
              variant="subtitle1"
              style={{
                color: "#FFFFFF",
              }}
            >
              ePackBook is a powerful Packers and Movers Software to manage
              entire day to day operations Like.
            </Typography>
            <Typography
              variant="subtitle1"
              style={{
                color: "#FFFFFF",
              }}
            >
              Manage Packers Enquiries - Add Enquiry, Set Survey, Follow Ups
              Date & Time, Create and Share Quotations, Manage Shiftings -
              Comments,
              <br /> Create & Share Bilty, Money Receipt, Car Conditions,
              Packing Slip & Invoice, Manage Customers, Download Reports.
            </Typography>
            <Typography
              variant="subtitle1"
              style={{
                color: "#FFFFFF",
              }}
            >
              ePackBook is a Cloud Based Software with High Data Security
              features. it can be used on any Device. Free Training & Support
              Available 24x7
            </Typography>
          </Box>
        </Box>
        <RegisterForDemo handleClose={handleClose} open={openRegisterForDemo} />
        <VerificationModal
          open={openFreeAccountModal}
          handleClose={handleCloseFreeAccount}
          heading={"Get Free Account"}
        />
      </Box>
      <Box className="noteSec">
        <Grid container sx={{display:'flex',alignItems:'center'}}>
          <Grid item md={1}></Grid>
          <Grid item xs={12} md={8}>
            <Typography variant="h6" color={"#fff"}>
            ePackBook मोबाइल एप Google Play
              Store पे आ गया है! आप हमारा
              एप Download करे एवं प्रयोग करें
            </Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <Link
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=com.epackbook"
                )
              }
            >
              <Tooltip title="Click To Download" placement="top-start">
                <IconButton>
                  <img src={PlayStoreLogo} width={200} height={50} />
                </IconButton>
              </Tooltip>
            </Link>
          </Grid>
          <Grid item md={1}></Grid>

        </Grid>
      </Box>
    </Fragment>
  );
};

export default Hero;
