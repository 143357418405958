import axios from "axios";

export const getCityInfoByPincode = async (value) => {
    const result = await axios.get(`https://api.postalpincode.in/pincode/${value}`);
    if (result?.data && result?.data.length > 0) {
        const transform = result?.data[0].PostOffice.map(d => {
            const item = {
                city: d.Block,
                country: d.Country,
                district: d.District,
                postOfficeName: d.Name,
                postalCode: d.Pincode,
                state: d.State
            }
            return item;
        });
        return transform
    }
}