import { takeLatest, put, call } from "redux-saga/effects";
import { postRequest } from "../Network/ApiClient";
import { REGISTER_FOR_DEMO } from "../Network/Endpoints";
import {
  registerForDemoSuccess,
  registerForDemoFail,
} from "../Redux/Slices/RegisterForDemo.Slice";

/**
 * Register For Demo Saga Api
 */
export function* registerForDemoSagaApi(action) {
  try {
    const data = yield call(postRequest, REGISTER_FOR_DEMO, action.payload);
    yield put(registerForDemoSuccess(data));
  } catch (error) {
    yield put(registerForDemoFail(error));
  }
}

/**
 * Saga Watchers
 */
export function* watcherRegisterForDemoSaga() {
    yield takeLatest('RegisterForDemoSlice/registerForDemoRequest', registerForDemoSagaApi);
}
