import { Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import react from "react";
import "./Style.css";


const DummyData = [
    {
        text: '1.Scheduling: Ability to create and manage schedules for multiple shifts and locations.'
    },
    {
        text: '2.Time and Attendance Tracking: Ability to track employee clock-in and clock-out times, absences and lateness.'
    },
    {
        text: '3. Communication: A platform for clear and efficient communication between managers, employees and stakeholders.'
    },
    {
        text: "4.Real-time Monitoring: Ability to monitor shifts in real-time and make adjustments as needed."
    },
    {
        text: '5. Reporting and Analytics: Access to real-time data and reports for decision making and continuous improvement.'
    },
    {
        text: "6. Task Management: Ability to assign tasks to employees and track their progress. "
    },
    {
        text: "7.Mobile Accessibility: Access to the platform via mobile devices to manage shifts and tasks on the go."
    },
    {
        text: '8.Integrations: Integration with other systems and tools, such as payroll or HR software.'
    },
    {
        text: '9. Cost Management: Ability to track and manage labor costs, including overtime and scheduling.'
    },
]


const ShiftingManagementView = () => {
    return (
        <Box className="shiftingManagement">
            <Container>
                <Typography variant="h4" style={{ marginBottom: 30, fontSize: 26 }}>
                    Shifting Management
                </Typography>
                <p style={{ marginBottom: "30px" }} >Here are some key features of effective shift management:</p>

                <ol>
                    {DummyData.map((_, i) => {
                        const txtArr = _.text.split(":");
                        return (

                            <li key={i}
                                style={{
                                    marginBottom: 30,
                                    listStyle: 'none', textAlign: "justify", lineHeight: 1.5
                                }}>
                                <span
                                    style={{ fontWeight: '600' }}>
                                    {txtArr[0]} &#58;</span>{txtArr[1]} </li>
                        )
                    })
                    }
                </ol>
            </Container>
        </Box>
    )

};


export default ShiftingManagementView;