import {
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { Container } from "@mui/system";
import React, { useState, useEffect } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextEditor from "../UIComponents/TextEditor";

// import INDIAN_STATES from "../../utils/INDIAN_STATES.json";
import ImagePicker from "../../Components/UIComponents/ImagePicker";
import LocationSelect from "../../Components/UIComponents/LocationSelect";
import {
  COMPANY_TYPES_CONSTANTS,
  ISO_CERTIFICATE_TYPES_CONSTANTS,
} from "../../Constant";
import { useDispatch, useSelector } from "react-redux";
import { validateLength } from "../CommanFunctions";
import WelcomePage from "../UIComponents/WelcomeMessage";
import Validation from "../../utils/Validations";
// import StateSelect from "../Ui/LocationSelect";
const style = {
  background: "white",
  // p: 2,
  mb: 2,
  mt: 2,
  textAlign: "center",
};

const CompanyRegistration = ({ formik, ibaValue }) => {
  const [ibaVal, setIbaVal] = useState(false);
  const verifyData = useSelector((state) => state.FreeAccountSlice.verifyData);
  // const isRegistered = useSelector((state) => state.RegistrationCompanySlice.success);
  const [ibaValidity, setIbaValidity] = useState(null);
  const [companyType, setCompanyType] = useState("");
  
  const registerCompany = useSelector((state) => state.RegisterCompanySlice);

  const handleChangeCompany = (e) => {
    setCompanyType(e.target.value);
    formik.setFieldValue("companyType", e.target.value);
  };

  const handleIba = () => {
    ibaValue(!ibaVal);
    setIbaVal((pre) => !pre);
  };
  
  const validations = new Validation()
  return (
    <Box position={"relative"}>
      <Container>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            marginTop: "20px",
          }}
        >
          <p style={{ color: "#5cb85c" }}>
            Your ePackBook Account has been created successfully. Now Submit
            Your Company Details to get ePackBook Login
            Credentials in your email.
          </p>
        </Box>
        <Box sx={style}>
          <h2>Enter Your Company Details</h2>
        </Box>
        <form onSubmit={formik.handleSubmit} noValidate>
          <Box sx={{ padding: "35px 0" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <ImagePicker
                  buttonName="Select Company Logo"
                  id="companyLogo"
                  formik={formik}
                  errorMsg={formik.errors.companyLogoErr}
                  fileType="image/png, image/jpeg"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <ImagePicker
                  id="companyProfilePdf"
                  buttonName="Select Company Profile"
                  formik={formik}
                  fileType="application/pdf"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  id="companyName"
                  label="Company Name"
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(evt)=>{
                    const {value} =evt.target
                    validations.validateAlphanumericString(formik, "companyName",value)
                  }}
                  value={formik.values.companyName}
                  error={
                    formik.errors.companyNameErr && formik.errors.companyNameErr
                  }
                  helperText={
                    formik.errors.companyNameErr && formik.errors.companyNameErr
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="companyPunchLine"
                  label="Company Punch Line"
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={formik.handleChange}
                  value={formik.values.companyPunchLine}
                  error={
                    formik.errors.companyPunchLineErr &&
                    formik.errors.companyPunchLineErr
                  }
                  helperText={
                    formik.errors.companyPunchLineErr &&
                    formik.errors.companyPunchLineErr
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  id="contactPerson"
                  label="Contact Person"
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(evt)=>{
                    const {value} =evt.target
                    validations.validateCharacterString(formik, "contactPerson",value)
                  }}
                  value={formik.values.contactPerson}
                  error={
                    formik.errors.contactPersonErr &&
                    formik.errors.contactPersonErr
                  }
                  helperText={
                    formik.errors.contactPersonErr &&
                    formik.errors.contactPersonErr
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  id="email"
                  label="Email"
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  error={formik.errors.emailErr && formik.errors.emailErr}
                  helperText={formik.errors.emailErr && formik.errors.emailErr}
                  disabled={verifyData?.user?.email}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="phone"
                  label="Landline"
                  inputProps={{
                    step: 1,
                    inputMode: 'numeric',
                    min: 0,
                  }}
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) => { 
                    let { value } = e.target
                    value = value.replace(/\D/g, "");
                    validateLength(formik, "phone", value, 11);
                  }}
                  value={formik.values.phone}
                  error={formik.errors.phoneErr && formik.errors.phoneErr}
                  helperText={formik.errors.phoneErr && formik.errors.phoneErr}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="mobile"
                  label="Mobile"
                  inputProps={{
                    step: 1,
                    inputMode: 'numeric',
                    min: 0,
                  }}
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) => { 
                    let { value } = e.target;
                    value = value.replace(/\D/g, "");
                    validateLength(formik, "mobile", value, 10);
                  }}
                  value={formik.values.mobile}
                  error={formik.errors.mobileErr && formik.errors.mobileErr}
                  helperText={
                    formik.errors.mobileErr && formik.errors.mobileErr
                  }
                  disabled={verifyData?.user?.phone_number}
                />
              </Grid>
              <Grid item xs={12}>
                <LocationSelect
                  resetPostalCodeFields={false}
                  postalCode={{
                    id: "pincode",
                    value: formik.values.pincode,
                    error: formik.errors.pincodeErr,
                  }}
                  state={{
                    id: "state",
                    value: formik.values.state,
                  }}
                  city={{
                    id: "city",
                    value: formik.values.city,
                  }}
                  postOffice={{
                    id: "postOffice",
                    value: formik.values.postOffice,
                  }}
                  district={{
                    id: "district",
                    value: formik.values.district,
                  }}
                  handleStateChange={(selected_state) => {
                    formik.setFieldValue("state", selected_state);
                  }}
                  handleCityChange={(selected_city) => {
                    formik.setFieldValue("city", selected_city);
                    formik.setFieldValue("shiftingTo", selected_city);
                  }}
                  handlePostalCodeChange={(entered_postalCode) => {
                    
                    entered_postalCode = entered_postalCode.replace(/\D/g, "");
                    validateLength(formik, "pincode", entered_postalCode, 6)
                  }}
                  handleDistrictChange={(entered_district) => {
                    formik.setFieldValue("district", entered_district);
                  }}
                  handlePostOfficeNameChange={(entered_postOffice) => {
                    formik.setFieldValue("postOffice", entered_postOffice);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  id="address"
                  label="Address"
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={formik.handleChange}
                  value={formik.values.address}
                  error={formik.errors.addressErr && formik.errors.addressErr}
                  helperText={
                    formik.errors.addressErr && formik.errors.addressErr
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="landmark"
                  label="Landmark"
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={formik.handleChange}
                  value={formik.values.landmark}
                  error={formik.errors.landmarkErr && formik.errors.landmarkErr}
                  helperText={
                    formik.errors.landmarkErr && formik.errors.landmarkErr
                  }
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  id="country"
                  label="Country"
                  variant="outlined"
                  size="small"
                  disabled
                  fullWidth
                  onChange={formik.handleChange}
                  value={formik.values.country}
                  error={formik.errors.countryErr && formik.errors.countryErr}
                  helperText={
                    formik.errors.countryErr && formik.errors.countryErr
                  }
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  id="companyWebsite"
                  label="Company Website"
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={formik.handleChange}
                  value={formik.values.companyWebsite}
                  error={
                    formik.errors.companyWebsiteErr &&
                    formik.errors.companyWebsiteErr
                  }
                  helperText={
                    formik.errors.companyWebsiteErr &&
                    formik.errors.companyWebsiteErr
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="gstNo"
                  label="GST. NO."
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) => {
                    const val = e.target.value.toUpperCase();
                    formik.setFieldValue(
                      "gstNo",
                      val.slice(0, 15).toUpperCase()
                    );
                  }}
                  value={formik.values.gstNo}
                  error={formik.errors.gstNoErr && formik.errors.gstNoErr}
                  helperText={formik.errors.gstNoErr && formik.errors.gstNoErr}
                />
              </Grid>
              <Grid item >
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <h4>IBA Approval</h4>
                  <RadioGroup
                    row
                    name="ibaApproval"
                    onChange={handleIba}
                    defaultValue="0"
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio size="small" />}
                      label="Approved"
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio size="small" />}
                      label="Not Approved"
                    />
                  </RadioGroup>
                </Box>
              </Grid>

              {ibaVal && (
                <>
                  <Grid item xs={12} md={4}>
                    <TextField
                      required
                      id="ibaApprovalCode"
                      label="IBA Approval Code"
                      variant="outlined"
                      size="small"
                      fullWidth
                      onChange={(e) => { 
                        let { value } = e.target
                       value= value.replace(/[^a-zA-Z0-9-]/g, "");
                        validateLength(
                          formik,
                          "ibaApprovalCode",
                          value.toUpperCase(),
                          8
                        );
                      }
                      }
                      value={formik.values.ibaApprovalCode}
                      error={
                        formik.errors.ibaApprovalCodeErr &&
                        formik.errors.ibaApprovalCodeErr
                      }
                      helperText={
                        formik.errors.ibaApprovalCodeErr &&
                        formik.errors.ibaApprovalCodeErr
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        id="ibaCodeValidTill"
                        label="IBA Code valid Till"
                        value={ibaValidity}
                        onChange={(d) => {
                          setIbaValidity(d);
                          formik.setFieldValue("ibaCodeValidTill", d.$d);
                        }}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            required
                            size="small"
                            {...params}
                            error={
                              formik.errors.ibaCodeValidTillErr &&
                              formik.errors.ibaCodeValidTillErr
                            }
                            helperText={
                              formik.errors.ibaCodeValidTillErr &&
                              formik.errors.ibaCodeValidTillErr
                            }
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </>
              )}

              <Grid item xs={12} md={ibaVal?6:4}>
                <TextField
                  id="udyamRegistration"
                  label="Udyam registration No."
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) => { 
                    let { value } = e.target;
                    value = value.replace(/[^a-zA-Z0-9-]/g, "");
                    validateLength(
                      formik,
                      "udyamRegistration",
                      value.toUpperCase(),
                      19
                    );
                  }
                  }
                  value={formik.values.udyamRegistration}
                  error={
                    formik.errors.udyamRegistrationErr &&
                    formik.errors.udyamRegistrationErr
                  }
                  helperText={
                    formik.errors.udyamRegistrationErr &&
                    formik.errors.udyamRegistrationErr
                  }
                />
              </Grid>
              <Grid item xs={12} md={ibaVal?6:4}>
                <TextField
                  id="shopAct"
                  label="Shop Act. No."
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) => { 
                    let { value } = e.target
                    value = value.replace(/[^a-zA-Z0-9-/]/g, "");
                    validateLength(
                      formik,
                      "shopAct",
                      value.toUpperCase(),
                      20
                    );
                  }
                    
                    
                  }
                  value={formik.values.shopAct}
                  error={formik.errors.shopActErr && formik.errors.shopActErr}
                  helperText={
                    formik.errors.shopActErr && formik.errors.shopActErr
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  id="isoCerificate"
                  label="ISO Certificate Type"
                  variant="outlined"
                  size="small"
                  fullWidth
                  select
                  onChange={(e) =>
                    formik.setFieldValue("isoCerificate", e.target.value)
                  }
                  value={formik.values.isoCerificate}
                  error={
                    formik.errors.isoCerificateErr &&
                    formik.errors.isoCerificateErr
                  }
                  helperText={
                    formik.errors.isoCerificateErr &&
                    formik.errors.isoCerificateErr
                  }
                >
                  {Object.values(ISO_CERTIFICATE_TYPES_CONSTANTS).map(
                    ({ key, text }, i) => (
                      <MenuItem key={i} value={key}>
                        {text}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </Grid>
              <Grid item xs={12} md={companyType !== "" ? 6 : 6}>
                <TextField
                  required
                  id="companyType"
                  label="Company Type"
                  variant="outlined"
                  size="small"
                  fullWidth
                  select
                  onChange={(event) => {
                    handleChangeCompany(event);
                    formik.setFieldValue("panNo", "");
                    formik.setFieldValue("incNo", "");
                  }}
                  value={formik.values.companyType}
                  error={
                    formik.errors.companyTypeErr && formik.errors.companyTypeErr
                  }
                  helperText={
                    formik.errors.companyTypeErr && formik.errors.companyTypeErr
                  }
                >
                  {[
                    COMPANY_TYPES_CONSTANTS.SOLE_PROPRIETORSHIP,
                    COMPANY_TYPES_CONSTANTS.PARTNERSHIP,
                    COMPANY_TYPES_CONSTANTS.LLP,
                    COMPANY_TYPES_CONSTANTS.PRIVATE_LIMITED,
                  ].map((option) => (
                    <MenuItem key={option.key} value={option.key}>
                      {option.text}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {companyType !== "" &&
                (companyType ===
                  COMPANY_TYPES_CONSTANTS.SOLE_PROPRIETORSHIP.key ||
                companyType === COMPANY_TYPES_CONSTANTS.PARTNERSHIP.key ? (
                  <Grid item xs={12} md={6}>
                    <TextField
                      required
                      id="panNo"
                      label="PAN No."
                      variant="outlined"
                      size="small"
                      fullWidth
                      onChange={(e) => {
                        const {value} = e.target
                        validateLength( formik,"panNo", value.toUpperCase(), 10 )
                      }}
                      value={formik.values.panNo}
                      error={formik.errors.panNoErr && formik.errors.panNoErr}
                      helperText={
                        formik.errors.panNoErr && formik.errors.panNoErr
                      }
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12} md={6}>
                    <TextField
                      required
                      id="incNo"
                      label="Incorporation No."
                      variant="outlined"
                      size="small"
                      fullWidth
                      onChange={(e) => {
                        const val = e.target.value.toUpperCase();
                        formik.setFieldValue("incNo", val);
                      }}
                      value={formik.values.incNo}
                      error={formik.errors.incNoErr && formik.errors.incNoErr}
                      helperText={
                        formik.errors.incNoErr && formik.errors.incNoErr
                      }
                    />
                  </Grid>
                ))}

              {registerCompany.fail && (
                <Grid xs={12}>
                  <small style={{ color: "#ff4842", marginLeft: 20 }}>
                    {registerCompany.data}
                  </small>
                </Grid>
              )}
              <Grid item xs={12}>
                <Button
                  type="submit"
                  disabled={registerCompany.requesting}
                  fullWidth
                  variant="contained"
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Container>
    </Box>
  );
};

export default CompanyRegistration;
