import { Card, Grid, Container, Box, TextField, Divider, Button, MenuItem, useMediaQuery } from "@mui/material";
import { FaRegCheckCircle } from 'react-icons/fa';
import { Link } from "react-router-dom";


const data = [
    {
        label: 'Enquiry Management'
    },
    {
        label: 'Survey Reminder'
    },
    {
        label: 'Create & Send Quotation 100 per month'
    },
    {
        label: 'Multi company Quotation'
    },
    {
        label: 'Mail Communication'
    },
    {
        label: 'Order Management'
    },
    {
        label: 'Clients Management'
    },
    {
        label: 'Download Reports'
    },
]

const Checkout = () => {
    const isMobile = useMediaQuery('(max-width:768px)');
    const price = {
        subTotal: 5000,
        couponDiscount: 2000,
        total: 5000,
        netAmount: 10000,
        gst: 200
    }


    return (
        <Box sx={{ padding: '60px 30px' }}>
            <Container>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={8}>
                        <Card sx={{ p: 3, background: '#f5f5f5' }}>
                            <h2 style={{ marginBottom: 18 }} >Grow your Business with us </h2>
                            <Box className="memberShipCard">

                                {isMobile ? (
                                    <h2>this is mobile</h2>
                                ) : (
                                    <table>
                                        <tr>
                                            <th align="left" >Plan</th>
                                            <th align="left">Duration</th>
                                            <th align="right">Price</th>
                                        </tr>
                                        <tr>
                                            <td>
                                                <h2 style={{ color: '#00A1E5' }}>PackBook Pro</h2>
                                            </td>
                                            <td>
                                                <TextField
                                                    select
                                                    label="Duration"
                                                    defaultValue="6 month"
                                                    fullWidth
                                                    size="small"
                                                    sx={{ width: 200 }}
                                                >
                                                    <MenuItem value='3 month'>
                                                        3 Month
                                                    </MenuItem>
                                                    <MenuItem value='6 month'>
                                                        6 Month
                                                    </MenuItem>
                                                    <MenuItem value='12 month'>
                                                        12 Month
                                                    </MenuItem>
                                                </TextField>
                                            </td>
                                            <td align="right">
                                                <h3>₹ 5000</h3>
                                            </td>
                                        </tr>
                                    </table>
                                )}

                                <Box className="features">
                                    <Grid container spacing={2}>
                                        {data.map((_, i) => (
                                            <Grid item xs={12} md={6}>
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <FaRegCheckCircle size={20} style={{ marginRight: 8, color: '#00CC60' }} />
                                                    <h5>{_.label}</h5>
                                                </Box>
                                            </Grid>
                                        ))}
                                    </Grid>

                                </Box>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card sx={{ p: 3, background: '#f5f5f5' }}>
                            <Box>
                                <h2 style={{ marginBottom: '18px' }}>Order Summery</h2>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex', alignItems: 'center', border: '1px solid', borderColor: '#c4c4c4', borderRadius: 1
                                }}>
                                <TextField
                                    label='Enter referral code'
                                    fullWidth
                                    size="small"
                                    // {...formik.getFieldProps('emailOrMobile')}
                                    // error={formik.errors.emailOrMobileErr && formik.errors.emailOrMobileErr}
                                    sx={{
                                        "& fieldset": { border: 'none' },
                                    }}
                                />
                                <Box>
                                    <Button sx={{ ml: 1 }} /*onClick={handleVerifyOtp}*/  >Apply</Button>
                                </Box>
                            </Box>
                            <Divider sx={{ my: 2 }} />
                            <Box display={'flex'} justifyContent={'space-between'} >
                                <h4>
                                    Subtotal
                                </h4>
                                <h4>
                                    ₹ {price.subTotal}
                                </h4>
                            </Box>
                            <Box display={'flex'} justifyContent={'space-between'} marginY={1} >
                                <h4 style={{ color: "red" }}>
                                    Coupon Discount
                                </h4>
                                <h4 style={{ color: "red" }}>
                                    -₹ {price.couponDiscount}
                                </h4>
                            </Box>
                            <Divider sx={{ my: 1.5 }} ></Divider>
                            <Box display={'flex'} justifyContent={'space-between'} marginY={1} >
                                <h4>
                                    Net Amount
                                </h4>
                                <h4>
                                    ₹ {price.netAmount}
                                </h4>
                            </Box>

                            <Box display={'flex'} justifyContent={'space-between'} marginY={1}>
                                <h4>
                                    GST 18%
                                </h4>
                                <h4>
                                    ₹ {price.gst}
                                </h4>
                            </Box>

                            <Divider sx={{ my: 2 }} />
                            <Box display={'flex'} justifyContent={'space-between'} >
                                <h4>
                                    Total Amount
                                </h4>
                                <h4>
                                    ₹ {price.total}
                                </h4>
                            </Box>
                            <Button component={Link} variant="contained" fullWidth sx={{ textTransform: 'capitalize', mt: 2 }}
                                to="/company-registration"
                            >Proceed</Button>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Box >
    )
}
export default Checkout;