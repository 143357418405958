import { Box } from "@mui/system";
import react from "react";
import "./Style.css";




const AutomaticEmailView =() => {

    return(
        <Box className="automaticEmails">
        <h1>AutomaticEmailView</h1>
        </Box>
    )
};

export default AutomaticEmailView;