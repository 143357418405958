import {
  Box,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { Link } from "react-router-dom";
import {
  AiFillTwitterCircle,
  AiFillYoutube,
  AiFillFacebook,
  AiFillInstagram,
} from "react-icons/ai";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { Container } from "@mui/system";
import logo from "../../Assets/Images/logo.png";
import { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiFillHome } from "react-icons/ai";
import { MdFeaturedPlayList } from "react-icons/md";
import { ImPriceTags } from "react-icons/im";
import { BiSupport } from "react-icons/bi";
import { MdLogin } from "react-icons/md";
import { ImBlog } from "react-icons/im";
import { IoLogIn } from "react-icons/io5";
import { MdOutlineAppRegistration } from "react-icons/md";
import "./style.css";

const navItems = [
  {
    label: "Home",
    path: "/",
    icon: <AiFillHome />,
  },
  {
    label: "Features",
    path: "/features",
    icon: <MdFeaturedPlayList />,
  },
  {
    label: "Pricing",
    path: "/pricing",
    icon: <ImPriceTags />,
  },
  // {
  //   label: "Blog",
  //   path: "/https://blog.epackbook.in/",
  //   icon: <ImBlog />,
  // },
  {
    label: "Contact Us",
    path: "/contact",
    icon: <BiSupport />,
  },

  // {
  //   label: "Register For Demo",
  //   path: "/register",
  //   icon: <MdOutlineAppRegistration />,
  // },
];

const iconitems = [
  {
    label: "Twitter",
    path: "https://google.com",
    icon: <AiFillTwitterCircle size={20} />,
  },
  {
    label: "Youtube",
    path: "https://google.com",
    icon: <AiFillYoutube size={20} />,
  },
  {
    label: "Facebook",
    path: "https://google.com",
    icon: <AiFillFacebook size={20} />,
  },
  {
    label: "Instagram",
    path: "https://google.com",
    icon: <AiFillInstagram size={20} />,
  },
];

const phoneNumbers = ["35353535353", "6363636363"];

const Header = () => {
  const [toggleDrawer, setToggleDeawer] = useState(false);
  const isTablate = useMediaQuery("(max-width:992px)");

  const handleToggleDrawer = () => setToggleDeawer((pre) => !pre);

  return (
    <Box className="header">
      <Box className="mainHeader">
        <Container>
          <Grid container>
            <Grid item xs={4}>
              <Box className="leftSide">
                <Link to="/">
                  <img src={logo} alt="image" />
                </Link>
              </Box>
            </Grid>
            <Grid
              item
              xs={8}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              {isTablate ? (
                <IconButton onClick={handleToggleDrawer} sx={{ padding: 2 }}>
                  <GiHamburgerMenu color="white" />
                </IconButton>
              ) : (
                <>
                  <Box className="rightSide">
                    {navItems.map((d, i) => (
                      <Box>
                        <Link key={i} to={d.path}>
                          {d.label}
                        </Link>
                      </Box>
                    ))}
                    <a
                      href={"https://blog.epackbook.in/"}
                      target="_blank"
                      style={{
                        color: "#FFFFFF",
                        fontSize: "16px",
                      }}
                    >
                      Blog
                    </a>
                  </Box>

                  {/* <a
                    href={process.env.REACT_APP_PACKERS_DASHBOARD_URL}
                    target="_blank"
                    style={{
                      color: "#FFFFFF",
                      fontSize: "16px",
                      marginLeft: 40,
                    }}
                  >
                    Packer Login
                  </a> */}
                </>
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Drawer
        open={toggleDrawer}
        onClose={handleToggleDrawer}
        className="mobileDrawer"
      >
        <List sx={{ width: 250 }}>
          {navItems.map((d, i) => (
            <ListItem key={i} disablePadding>
              <Link
                to={d.path}
                onClick={() => setToggleDeawer(false)}
                style={{ color: "#757575" }}
              >
                <ListItemButton>
                  <ListItemIcon>{d.icon}</ListItemIcon>
                  <ListItemText primary={d.label} />
                </ListItemButton>
              </Link>
            </ListItem>
          ))}
          <ListItem disablePadding>
            <a
              href={"https://blog.epackbook.in/"}
              target="_blank"
              style={{
                color: "rgb(117, 117, 117)",
                fontSize: "16px",
              }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <ImBlog />
                </ListItemIcon>
                <ListItemText>Blog</ListItemText>
              </ListItemButton>
            </a>
          </ListItem>
          <ListItem disablePadding>
            <a
              href={process.env.REACT_APP_PACKERS_DASHBOARD_URL}
              target="_blank"
              style={{
                color: "rgb(117, 117, 117)",
                fontSize: "16px",
              }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <MdLogin />
                </ListItemIcon>
                <ListItemText>Packer Login</ListItemText>
              </ListItemButton>
            </a>
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
};

export default Header;
