import { takeLatest, put, call } from "redux-saga/effects";
import { getRequest, postRequest } from "../Network/ApiClient";
import { SEND_EMAIL, VERIFY_EMAIL } from "../Network/Endpoints";
import {
  sendEmailSuccess,
  sendEmailFail,
  verifyEmailSuccess,
  verifyEmailFail,
} from "../Redux/Slices/FreeAccount.Slice";

/**
 * Send Email Saga Api
 */
export function* sendEmailSagaApi(action) {
  try {
    const data = yield call(postRequest, SEND_EMAIL, action.payload);
    yield put(sendEmailSuccess(data));
  } catch (error) {
    yield put(sendEmailFail(error));
  }
}

/**
 * Verify Email Saga Api
 */
export function* verifyEmailApi(action) {
  try {
    const data = yield call(postRequest, VERIFY_EMAIL, action.payload);
    yield put(verifyEmailSuccess(data));
  } catch (error) {
    yield put(verifyEmailFail(error));
  }
}

/**
 * Saga Watchers
 */
export function* watcherSendEmailSaga() {
  yield takeLatest("FreeAccountSlice/sendEmailRequst", sendEmailSagaApi);
  yield takeLatest("FreeAccountSlice/verifyEmailRequst", verifyEmailApi);
}
