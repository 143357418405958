import styled from "@emotion/styled";
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableRow,
  Typography,
  Button,
  Grid,
  MenuItem,
  TextField,
  Select,
  Divider,
  CardActions,
} from "@mui/material";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import plans from "./data/plans.json";
import "./Pricing.css";
import available from "../../Assets/icons/available.svg";
import notAvailable from "../../Assets/icons/notAvailable.svg";
import { useState } from "react";
import VerificationModal from "../UIComponents/VerificationModal";
import ComponentSubHeader from "../UIComponents/ComponentSubHeader";
import { Fragment } from "react";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#000",
    color: "#fff",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#eee",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function Pricing() {
  const [openVerificationModal, setOpenVerification] = useState(false);
  const _getFeatureName = (item) => {
    if (item.hasOwnProperty("feature_name")) {
      return item.feature_name;
    }
  };

  const _getIcon = (isAvailable, textOnly) => {
    if (textOnly) return null;
    return isAvailable ? (
      <img
        src={available}
        style={{ widht: "30px", height: "30px" }}
        alt="icon"
      />
    ) : (
      <img
        src={notAvailable}
        style={{ widht: "30px", height: "30px" }}
        alt="icon"
      />
    );
  };

  const _getFeatureValue = (item) => {
    if (item.hasOwnProperty("value_type") && item.value_type === "image") {
      return item.value ? (
        <img
          src={available}
          style={{ widht: "30px", height: "30px" }}
          alt="icon"
        />
      ) : (
        <img
          src={notAvailable}
          style={{ widht: "30px", height: "30px" }}
          alt="icon"
        />
      );
    } else if (
      item.hasOwnProperty("value_type") &&
      item.value_type === "text"
    ) {
      const services = item.value?.split(",");
      return (
        <Fragment>
          <head>
            <title>
              Sign up with ePackBook for Free | India’s First CRM for Packers
              and Movers
            </title>
            <meta
              name="description"
              content=" ePackBook offers Free Software to manage Packers and Movers Enquiries and Customers online. Get it Now!"
            />
          </head>
          <ul style={{ paddingLeft: "10px", textAlign: "left" }}>
            {services.map((s, i) => {
              return (
                <li key={`${i}`} style={{ padding: "2px" }}>
                  {s}
                </li>
              );
            })}
          </ul>
          {_getIcon(item.service_available, item?.text_only)}
        </Fragment>
      );
    }
  };

  const handleOpenFreeAccount = () => {
    setOpenVerification(true);
  };

  const handleCloseVerificationModal = () => {
    setOpenVerification(false);
  };

  return (
    <Fragment>
      <ComponentSubHeader
        heading={"Pricing"}
        current={"Pricing"}
        style={{ marginBottom: "0px !important" }}
      />

      <Box style={{ backgroundColor: "#eee", padding: "50px 0" }}>
        <Container>
          <Grid container spacing={2}>
            {plans.map((plan, i) => (
              <Grid item md={3} xs={12}>
                <Card key={i}>
                  <CardHeader
                    title={plan.plan_label}
                    style={{ textAlign: "center" }}
                  />
                  <CardContent>
                    <Select
                      fullWidth
                      variant="outlined"
                      size="small"
                      color="info"
                      disableUnderline
                      defaultValue={plan.duration[0].duration}
                      className="lkansdfklasd"
                    >
                      {plan.duration.map((_, i) => (
                        <MenuItem key={i} value={_.duration}>
                          <Box className="menuItems">
                            <Box className="menuItemsPrice">
                              <Box>
                                <small>{_.actualPrice}</small>
                              </Box>
                              <Box>
                                <h3>{_.discountedPrice}</h3>
                              </Box>
                            </Box>
                            <Box className="menuItemsDescount">
                              <h4>{_.duration}</h4>
                              <h4>{_.diacount}</h4>
                            </Box>
                          </Box>
                        </MenuItem>
                      ))}
                    </Select>
                    {/* <Link to={"/#signin"}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      sx={{ mt: 2 }}
                      onClick={handleOpenFreeAccount}
                    >
                      Get A Free Account
                    </Button>
                     </Link> */}
                  </CardContent>

                  <CardContent>
                    <TableContainer
                      component={Paper}
                      sx={{ boxShadow: "none" }}
                    >
                      <Table>
                        <TableBody>
                          {plan.features.map((feature) => (
                            <StyledTableRow key={feature.name}>
                              <StyledTableCell component="th" scope="column">
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div
                                    style={{
                                      textAlign: "center",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {_getFeatureName(feature)}
                                  </div>
                                  <div style={{ textAlign: "center" }}>
                                    {_getFeatureValue(feature)}
                                  </div>
                                </div>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CardContent>
                  {
                    <Box
                      style={{
                        display: "flex",
                        alignItem: "center",
                        justifyContent: "center",
                      }}
                    >
                      {/* <Button
                        variant="contained"
                        style={{ width: "100%", backgroundColor: "red" }}
                        onClick={() => handleOpenFreeAccount()}
                      >
                        Buy Now
                      </Button> */}
                    </Box>
                  }
                </Card>
              </Grid>
            ))}
          </Grid>
          <VerificationModal
            open={openVerificationModal}
            handleClose={handleCloseVerificationModal}
            heading={"Get ePackbook Account"}
          />
        </Container>
      </Box>
    </Fragment>
  );
}

export default Pricing;
