import { Box } from "@mui/system";
import react from "react";
import "./Style.css";


const CarConditionView = () => {

    return (
        <Box className="carCondition">
            <h1>CarConditionView</h1>
        </Box>
    )
};
export default CarConditionView;