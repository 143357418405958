import { Grid, Typography, Container } from "@mui/material";
import { Box } from "@mui/system";
import react from "react";
import "./Style.css";

const DummyData = [
    {
        text: "1.Contact Information: Storing client's contact information, including name, address, phone number, and email."
    },
    {
        text: "2. Move Details: Recording information about the client's move, including pickup and delivery locations, date, time, and items being moved."
    },
    {
        text: "3.Payment History: Tracking client's payment history, including invoices, payment methods, and payment status."
    },
    {
        text: "4. Communication Logs: Keeping a record of all communication with the client, including email, phone calls, and in-person meetings."
    },
    {
        text: "5.  Feedback and Ratings: Recording client feedback and ratings, which can be used for quality control and continuous improvement."
    },
    {
        text: "6. Service History: Keeping a record of all services provided to the client, including dates and type of services."
    },
    {
        text: "7.  Search and Filtering: Ability to"
    },

]

const CustomerModuleView = () => {

    return (

        <>
            <Container className="customerModule">
                <Grid Container>
                    <Grid item sx={12}>
                        <Typography variant="h4" style={{ marginBottom: 30, fontSize: 26 }}>
                            Client Management
                        </Typography>
                        <p style={{ marginBottom: 30 }}>A client database for a packers and movers company can have the following features:</p>

                        <ol>
                            {DummyData.map((_, i) => {
                                const txtArr = _.text.split(":");
                                return (

                                    <li key={i} style={{ marginBottom: 30, listStyle: 'none', lineHeight: 1.5, textAlign: "justyfy" }}><span style={{ fontWeight: '600' }}>{txtArr[0]} &#58;</span>{txtArr[1]} </li>
                                )
                            })
                            }
                        </ol>

                    </Grid>
                </Grid>
            </Container>
        </>
    )

};

export default CustomerModuleView; 
