import { Box } from "@mui/system";
import react from "react";
import "./Style.css";



const BiltyModuleView = () => {

    return (
        <Box className="biltyModule">
            <h1>BiltyModuleView</h1>
        </Box>

    )

};

export default BiltyModuleView;