import { Breadcrumbs, Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";
import ComponentSubHeader from "../UIComponents/ComponentSubHeader";
import "./Style.css";


const AboutUs = () => {


    React.useEffect(() => {
        window.scrollTo(0, 0);

    }, []);
    return (
        <>
           <ComponentSubHeader heading={'About Us'} current={'About Us'}/>
            <Box className="innerData">
                <Container>
                    <p ><strong>ePackBook </strong> is a cloud based online software to manage packers and movers business and developed by Essence Web Technology.  We are a software development firm based in Gurugram (HR) India. We found a big opportunity in logistic sector to manage their business online and create efficiency in work flow. To grab this opportunity we build <strong>ePackBook </strong> to bring value to the niche middle markets that are underserved in terms of operational efficiency.</p>
                    <p >We launched <strong> ePackbook</strong> to bring our technology to improve the performance of moving companies around the world.</p>
                    <p ><strong>ePackbook</strong>  is a creative idea of whole team of Essence Web Technology, the reason behind development of <strong>ePackbook</strong> was our packers portfolio. Most packers and movers companies use diary to manage their business. They forget their customers after a time period. This software <strong>"ePackBook"</strong> will enhance efficiency of packers and movers business.</p>

                    <p><strong>ePackBook</strong>  is now used by packers and movers companies across the India and it's a cloud based solution, you can use from anywhere around the world.</p>
                    <p>In Next few days, we will continue to offer better features and user friendly mobile Apps of <strong>ePackBook</strong>.</p>
                </Container>
            </Box>
        </>
    )
};
export default AboutUs;