import RefundPolicyComponent from "../Components/RefundPolicy";


const RefundPolicy = () => {

    return (
        <RefundPolicyComponent />
    )
};

export default RefundPolicy;