import { Box } from "@mui/material";
import { useEffect } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../style.css";
import ImageResize from "quill-image-resize-module-react";

Quill.register("modules/imageResize", ImageResize);

const EditorModules = {
    toolbar: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        ["blockquote", "code-block"],

        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }], // superscript/subscript
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ direction: "rtl" }], // text direction
        // ['link', 'image', 'video'],
        [{ size: ["small", false, "large", "huge"] }], // custom dropdown
        [{ header: [1, 2, 3, 4, 5, 6, false] }],

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ font: [] }],
        [{ align: [] }],

        ["clean"], // remove formatting button
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: true,
    },
    imageResize: {
        parchment: Quill.import("parchment"),
        modules: ["Resize", "DisplaySize"],
    },
};

const EditorFormats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
];

const TextEditor = ({
    id,
    formik,
    formikErr,
    placeholder,
    validate,
    reset,
    preFill,
}) => {
    useEffect(() => {
        if (reset) {
            formik.setFieldValue(id, "");
        }
    }, [reset]);
    useEffect(() => {
        if (preFill) {
            formik.setFieldValue(id, JSON.parse(preFill));
        }
    }, [preFill]);


    return (
        <Box>
            <Box
                className="textEditorOuter"
                sx={{
                    border: "1px solid #C4C4C4",
                    borderRadius: 1,
                }}
            >
                <ReactQuill
                    id={id}
                    modules={EditorModules}
                    theme="snow"
                    value={formik.values[id]}
                    onChange={(html) => formik.setFieldValue([id], html)}
                    formats={EditorFormats}
                    placeholder={placeholder}
                />
            </Box>
            {formikErr}
        </Box>
    );
};

export default TextEditor;
