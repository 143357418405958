import React, { Fragment } from "react";
import { Button, Grid } from "@mui/material";
import { Box, Container } from "@mui/system";
import "./Style.css";
import { IoSpeedometer } from "react-icons/io5";
import {
    HiClipboardList,
    HiUsers,
    HiBell,
    HiTruck,
    HiNewspaper,
    HiDocumentText,
    HiDocumentSearch,
    HiDocumentDownload,
    HiIdentification,
    HiMail,
    HiChartPie,
    HiLibrary,
    HiUserGroup,
    HiPencilAlt,
    HiDeviceMobile,
    HiDesktopComputer,
    HiCollection
} from "react-icons/hi";
import UserFriendlyDashboardView from "./components/UserFriendlyDashboardView/UserFriendlyDashboardView";
import EnquiryManagementView from "./components/EnquiryManagementView/EnquiryManagementView";
import RemienderSurveyView from "./components/RemienderSurveyFollowView/RemienderSurveyFollowView";
import CreateNSendQuotationView from "./components/CreateNSendQuotationView/CreateNSendQuotationView";
import ShiftingManagementView from "./components/ShiftingManagementView/ShiftingManagementView";
import MoneyRecieptView from "./components/MoneyRecieptView/MoneyRecieptView";
import BiltyModuleView from "./components/BiltyModuleView/BiltyModuleView";
import CarConditionView from "./components/CarConditionView/CarConditionView";
import InvoiceModuleView from "./components/InvoiceModuleView/InvoiceModuleView";
import CustomerModuleView from "./components/CustomerModuleView/CustomerModuleView";
import AutomaticEmailView from "./components/AutomaticEmailView/AutomaticEmailView";
import PowerfulReportsView from "./components/PowerfulReportsView/PowerfulReportsView";
import BranchManagementView from "./components/BranchManagementView/BranchManagementView";
import RoleManagementView from "./components/RoleManagementView/RoleManagementView";
import DigitalSignatureView from "./components/DigitalSignatureView/DigitalSignatureView";
import MobileAppView from "./components/MobileAppView/MobileAppView";
import DesktopView from "./components/DesktopView/DesktopView";
import MultiTemplateView from "./components/MultiTemplateView/MultiTemplateView";
import ComponentSubHeader from "../UIComponents/ComponentSubHeader";
import { useLocation } from 'react-router-dom';

const menuItems =
    [
        {
            "menu_id": 1,
            "menu_name": "User Friendly Dashboard",
            icon: <IoSpeedometer />
        },
        {
            "menu_id": 2,
            "menu_name": "Enquiry Management",
            icon: <HiClipboardList />
        },
        {
            "menu_id": 3,
            "menu_name": "Rermiender-Survey Follow",
            icon: <HiBell />
        },
        {
            "menu_id": 4,
            "menu_name": "Quotation Module",
            icon: <HiUsers />
        },
        {
            "menu_id": 5,
            "menu_name": "Shifting Management",
            icon: <HiTruck />
        },
        {
            "menu_id": 6,
            "menu_name": "Money Reciept",
            icon: <HiNewspaper />
        },
        {
            "menu_id": 7,
            "menu_name": "Bilty Module",
            icon: <HiDocumentText />
        },
        {
            "menu_id": 8,
            "menu_name": "Car Condition",
            icon: <HiDocumentSearch />
        },
        {
            "menu_id": 9,
            "menu_name": "Invoice Module",
            icon: <HiDocumentDownload />
        },
        {
            "menu_id": 10,
            "menu_name": "Customer Module",
            icon: <HiIdentification />
        },
        {
            "menu_id": 11,
            "menu_name": "Automatic Emails",
            icon: <HiMail />
        },
        {
            "menu_id": 12,
            "menu_name": "Powerfull Reports",
            icon: <HiChartPie />
        },
        {
            "menu_id": 13,
            "menu_name": "Branch Management",
            icon: <HiLibrary />
        },
        {
            "menu_id": 14,
            "menu_name": "Role Management",
            icon: <HiUserGroup />
        },
        {
            "menu_id": 17,
            "menu_name": "Digital Signature",
            icon: <HiPencilAlt />
        },
        {
            "menu_id": 18,
            "menu_name": "Mobile App",
            icon: <HiDeviceMobile />
        },
        {
            "menu_id": 19,
            "menu_name": "Desktop Software",
            icon: <HiDesktopComputer />
        },
        {
            "menu_id": 20,
            "menu_name": "Multi Templates",
            icon: <HiCollection />
        },

    ]

const FeaturesSection = (props) => {
    const { state } = useLocation();
    console.log('state----', state);
    const [currentMenuIndex, setCurrentMenuIndex] = React.useState(state?.index);

    const _onMenuItemPress = (index) => {
        setCurrentMenuIndex(index);
    }

    const _switchMenuContentView = () => {
        switch (currentMenuIndex) {
            case 0:
                return <UserFriendlyDashboardView />;
            case 1:
                return <EnquiryManagementView />;
            case 2:
                return <RemienderSurveyView />;
            case 3:
                return <CreateNSendQuotationView />;
            case 4:
                return <ShiftingManagementView />;
            case 5:
                return <MoneyRecieptView />;
            case 6:
                return <BiltyModuleView />;
            case 7:
                return <CarConditionView />;
            case 8:
                return <InvoiceModuleView />;
            case 9:
                return <CustomerModuleView />;
            case 10:
                return <AutomaticEmailView />;
            case 11:
                return <PowerfulReportsView />;
            case 12:
                return <BranchManagementView />;
            case 13:
                return <RoleManagementView />;
            case 14:
                return <DigitalSignatureView />;
            case 15:
                return <MobileAppView />;
            case 16:
                return <DesktopView />;
            case 17:
                return <MultiTemplateView />;
            default:
                return <UserFriendlyDashboardView />;
        }
    }

    React.useEffect(() => {
        window.scrollTo(0, 0);

    }, []);

    return (
        <Fragment>
        <head>
        <title>Explore ePackBook Features by using our Software and grow your Business
        </title>
        <meta
          name="description"
          content="Grow Packers and Movers Business online with ePackBook Powerful software for Relocation Business. Explore ePackBook features"
        />
      </head>
            <ComponentSubHeader heading={'Features'} current={'Features'} />
            <Box className="featuresButtonWr">
                <Container>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3} className='buttonsWrapper'>
                            <Box className="featureButtons">
                                {menuItems.map((menu, i) => {
                                    return (
                                        <Box mr={1}>
                                            <Button
                                                variant="contained"
                                                startIcon={menu.icon}
                                                onClick={() => _onMenuItemPress(i)}
                                                fullWidth
                                                style={{
                                                    backgroundColor: currentMenuIndex === i ? '#068092' : "#26AFC3", padding: "15px",
                                                    display: "flex",
                                                    justifyContent: "flex-start",
                                                    border: "1px solid #f4f4f4",
                                                    fontSize: '12px'
                                                }}>
                                                {menu.menu_name}
                                            </Button>
                                        </Box>

                                    )
                                })}
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={9}>
                            <Box className="rightContent">
                                {_switchMenuContentView()}
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

        </Fragment>
    )


};

FeaturesSection.defaultProps = {
    data: [

    ]
};


export default FeaturesSection;