import React from "react";
import {
  Grid,
  TextField,
  Typography,
  MenuItem,
  Dialog,
  IconButton,
  Slide,
  Button,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import loginimg from "../../Assets/Images/login.png";
import { useFormik } from "formik";
import { forwardRef, useState } from "react";
import "./style.css";
import { IoMdClose } from "react-icons/io";
import INDIAN_STATES from "../Static/INDIAN_STATES.json";
import { useDispatch, useSelector } from "react-redux";
import {
  registerForDemoRequest,
  reset,
} from "../../Redux/Slices/RegisterForDemo.Slice";
import toast from "react-hot-toast";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RegisterForDemo = ({ handleClose, open }) => {
  const dispatch = useDispatch();
  const {requesting, success, fail, data} = useSelector(state => state.RegisterForDemoSlice);
  const formik = useFormik({
    initialValues: {
      companyName: "",
      email: "",
      mobile: "",
      state: "",
      city: "",
      contactPerson: "",
    },
    validateOnChange: false,
    validateOnBlur: false,
    validate: (values) => {
      const errors = {};
      const emailRegExp = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+?\.[a-zA-Z]{2,3}$/;
      var digitOnly = /^\d{10}$/;
      if (!values.companyName) {
        errors.companyNameErr = "Field is required";
      }
      if (!values.email) {
        errors.emailErr = "Field is required";
      }
      if (!emailRegExp.test(values.email)) {
        errors.emailErr = "Please enter valid email";
      }
      if (!values.mobile) {
        errors.mobileErr = "Field is required";
      }
      if(!values.mobile.match(digitOnly)){
        errors.mobileErr = "Please enter valid mobile number";
      }
      if (!values.state) {
        errors.stateErr = "Field is required";
      }
      if (!values.city) {
        errors.cityErr = "Field is required";
      }
      if (!values.contactPerson) {
        errors.contactPersonErr = "Field is required";
      }
      return errors;
    },
    onSubmit: (values) => {
      console.log('form-submited',values);
      const _data = {
        company_name: values.companyName,
        contact_person: values.contactPerson,
        email: values.email,
        mobile_number: values.mobile.toString(),
        state: values.state,
        city: values.city,
      }
      dispatch(registerForDemoRequest(_data));
    },
  });

  const _handleClose = () => {
    formik.resetForm();
    handleClose && handleClose();
  }

  React.useEffect(() => {
    if (!requesting && success) {
      toast.success(`${data?.message}`, {id: "demo_register_success",});
      dispatch(reset());
      _handleClose();
    } else if (!requesting && fail) {
      toast.error("Something went wrong!", { id: "demo_register_error2" });
      dispatch(reset());
    }
  }, [requesting, success, fail]);

  return (
    
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={_handleClose}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="lg"
    >
      <Box sx={{ overflow: "hidden" }}>
        <Box sx={{ padding: 2 }}>
          <IconButton
            onClick={_handleClose}
            sx={{
              float: "right",
            }}
          >
            <IoMdClose />
          </IconButton>
        </Box>
        <Box className="loginCardWrapper">
          <Container>
            <Box className="login" p={3}>
              <Container>
                <Grid container spacing={5}>
                  <Grid item xs={1} md={6}>
                    <Box className="rightSide">
                      <img src={loginimg} alt="image" />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                  <form onSubmit={formik.handleSubmit}>
                      <Box className="leftSide">
                        <Box sx={{ textAlign: "left", width: "100%", mb: 2 }}>
                          <Typography variant="h4">
                            {" "}
                            Register For Demo
                          </Typography>
                        </Box>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <TextField
                              {...formik.getFieldProps("companyName")}
                              label="Company Name"
                              variant="outlined"
                              size="small"
                              fullWidth
                              error={!!formik.errors.companyNameErr}
                              helperText={formik.errors.companyNameErr}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              label="Contact Person"
                              variant="outlined"
                              size="small"
                              fullWidth
                              {...formik.getFieldProps("contactPerson")}
                              error={!!formik.errors.contactPersonErr}
                              helperText={formik.errors.contactPersonErr}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              label="Enter Email"
                              variant="outlined"
                              size="small"
                              {...formik.getFieldProps("email")}
                              fullWidth
                              error={!!formik.errors.emailErr}
                              helperText={formik.errors.emailErr}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              label="Mobile Number"
                              variant="outlined"
                              size="small"
                              {...formik.getFieldProps("mobile")}
                              fullWidth
                              error={!!formik.errors.mobileErr}
                              helperText={formik.errors.mobileErr}
                              inputProps={{
                                maxLength: 10,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              label="State"
                              variant="outlined"
                              size="small"
                              {...formik.getFieldProps("state")}
                              fullWidth
                              select
                              error={!!formik.errors.stateErr}
                              helperText={formik.errors.stateErr}
                            >
                              {INDIAN_STATES.map((state, index) => (
                                <MenuItem value={state} key={index}>
                                  {state}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              label="City"
                              variant="outlined"
                              size="small"
                              fullWidth
                              {...formik.getFieldProps("city")}
                              error={!!formik.errors.cityErr}
                              helperText={formik.errors.cityErr}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Button
                              type="submit"
                              variant="contained"
                              fullWidth
                            >
                              Send
                              </Button>
                          </Grid>
                        </Grid>
                      </Box>
                      </form>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </Container>
        </Box>
      </Box>
    </Dialog>
  );
};
export default RegisterForDemo;
