import React from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
} from "react-router-dom";
import "./index.css";
import { routes } from "./routes";
import { Provider } from "react-redux";
import { store } from "../src/Redux/store";
import { Toaster } from "react-hot-toast";
import UIBackdrop from "./Components/UIComponents/Backdrop";

const router = createBrowserRouter(createRoutesFromElements(routes));
const root = document.getElementById("root");
createRoot(root).render(
  <Provider store={store}>
    <RouterProvider router={router} />
    <UIBackdrop />
    <Toaster />
  </Provider>
);
