import { regax } from "./constants";
class Validation {
    
    isValidPanCard(pan){
        if(regax.PAN_CARD.test(pan)) {
            return true
        }
        else {
            return false
        }
        
    }
    isValidIndianMobile(mobile){
        if(regax.INDIAN_MOBILE.test(mobile)) {
            return true
        }
        else {
            return false
        }
    }
    isValidEmailAddress(email) {
        if(regax.EMAIL_ADDRESS.test(email)) {
            return true
        } else {
            return false
        }
    }
    isValidCompanyNameOrPersonName(name) {
        if(regax.NAMES.test(name)) {
            return true
        } else {
            return false
        }
    }
    isValidPincode(pin) {
        if(regax.PINCODE.test(pin)) {
            return true
        } else {
            return false
        }
    }
    isValidPhone(number) {
        if(regax.PHONE.test(number)) {
            return true
        } else {
            return false
        }
    }
    validateCharacterString(formik, id, value){
        const result = value.replace(regax.NAMES, "");
        formik.setFieldValue(id, result)

    }
    validateAlphanumericString(formik, id,value){
        const result = value.replace(regax.COMPANY_NAME, "");
        formik.setFieldValue(id, result)
    }
}
export default Validation