import { Box } from "@mui/material";
import react from "react";
import "./Style.css";






const RemienderSurveyView = () => {

    return (
        <Box className="remienderSurvey">
            <h1>hello</h1>
        </Box>
    )


};

export default RemienderSurveyView;