import { takeLatest, put, call } from "redux-saga/effects";
import { postRequest } from "../Network/ApiClient";
import { REGISTER_COMPANY } from "../Network/Endpoints";
import { resetBackdrop, showBackdrop, showWelcomeBackdrop } from "../Redux/Slices/backdrop";
import {
  registerCompanySuccess,
  registerCompanyFail,
} from "../Redux/Slices/RegisterCompany.Slice";


/**
 * Register Company Saga Api
 */
export function* registerCompanySagaApi(action) {
  try {
    yield put(showBackdrop());
    const data = yield call(postRequest, REGISTER_COMPANY, action.payload);
    yield put(registerCompanySuccess(data));
    yield put(resetBackdrop());
    yield put(showWelcomeBackdrop());
    setTimeout(() => {
      window.open(process.env.REACT_APP_PACKERS_DASHBOARD_URL, "_self");
    }, 8000)
  } catch (error) {
    console.log("error -- ", error);
    yield put(registerCompanyFail(error.message));
    yield put(resetBackdrop());
  }
}

/**
 * Saga Watchers
 */
export function* watcherRegisterCompanySaga() {
  yield takeLatest(
    "RegisterCompanySlice/registerCompanyRequest",
    registerCompanySagaApi
  );
}
