import { Box, Button, Card, CardContent, Grid, TextField } from "@mui/material"
import { useFormik } from "formik"


const emailregex = "!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i";
const nameregex = "[a-zA-Z][a-zA-Z ]+";
export const Contactform = () => {
    const formik = useFormik({
        initialValues: {
            companyName: '',
            contactPerson: '',
            businuessMail: '',
            contactNo: '',
            message: ''
        },
        validate: (values) => {
            const errors = {}
            if (!values.companyName) {
                errors.companyNameErr = 'Please enter your company name'
            }
            if (values.companyName && !values.companyName.match(nameregex)) {
                errors.companyNameErr = 'Please enter your valid company name'
            }
            if (!values.contactPerson) {
                errors.contactPersonErr = 'Please enter your contact person'
            }
            if (values.contactPerson && !values.contactPerson.match(emailregex)) {
                errors.contactPersonErr = 'Please enter your valid contactPerson'
            }
            if (!values.businuessMail) {
                errors.businuessMailErr = 'Please enter mail id'
            }
            if (!values.contactNo) {
                errors.contactNoErr = 'Please enter your mobile no'
            }
            if (!values.message) {
                errors.messageErr = 'Please enter your message'
            }
            return errors;
        },
        onSubmit: (values) => {
            console.log('this is my values', values)
        }
    })


    return (
        <>
            <Box className="contformItem">
                <Card>
                    <CardContent>
                        <form onSubmit={formik.handleSubmit} >
                            <Grid container spacing={2}>
                                <Grid item xs="12"  >
                                    <TextField
                                        id="companyName"
                                        size="small"
                                        label="Company Name"
                                        fullWidth
                                        value={formik.values.companyName}
                                        onChange={formik.handleChange}
                                        error={formik.errors.companyNameErr && formik.errors.companyNameErr}
                                        helperText={formik.errors.companyNameErr && formik.errors.companyNameErr}
                                    />
                                </Grid>
                                <Grid item xs="12"  >
                                    <TextField
                                        id='contactPerson'
                                        size="small"
                                        label="Contact Person"
                                        fullWidth
                                        value={formik.values.contactPerson}
                                        onChange={formik.handleChange}
                                        error={formik.errors.contactPersonErr && formik.errors.contactPersonErr}
                                        helperText={formik.errors.contactPersonErr && formik.errors.contactPersonErr}

                                    />
                                </Grid>
                                <Grid item xs="12"  >
                                    <TextField
                                        id="businuessMail"
                                        size="small"
                                        label="Business Mail"
                                        fullWidth
                                        value={formik.values.businuessMail}
                                        onChange={formik.handleChange}
                                        error={formik.errors.businuessMailErr && formik.errors.businuessMailErr}
                                        helperText={formik.errors.businuessMailErr && formik.errors.businuessMailErr}
                                    />
                                </Grid>
                                <Grid item xs="12"  >
                                    <TextField
                                        id="contactNo"
                                        size="small"
                                        label="Mobile No"
                                        fullWidth
                                        value={formik.values.contactNo}
                                        onChange={formik.handleChange}
                                        error={formik.errors.contactNoErr && formik.errors.contactNoErr}
                                        helperText={formik.errors.contactNoErr && formik.errors.contactNoErr}
                                    />
                                </Grid>
                                <Grid item xs="12">
                                    <TextField
                                        id="message"
                                        size="small"
                                        rows={3}
                                        multiline
                                        label="Message"
                                        fullWidth
                                        value={formik.values.message}
                                        onChange={formik.handleChange}
                                        error={formik.errors.messageErr && formik.errors.messageErr}
                                        helperText={formik.errors.messageErr && formik.errors.messageErr}
                                    />
                                </Grid>
                                <Grid item xs="12" sx={{ textAlign: "center" }}>
                                    <Button
                                        type="submit"
                                        sx={{ width: "200px" }}
                                        variant="contained"
                                        size="large">
                                        Send
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Box>

        </>
    )
}
