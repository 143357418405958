import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import VerificationModal from "../UIComponents/VerificationModal";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { IoLogoTwitter } from "react-icons/io";
import "./style.css";

function SocialMediaIconsJSX(props) {
  if (!props.IconsArr) return null;
  return (
    <Stack direction="row" spacing={0}>
      {props.IconsArr.map(({ icon, path }, i) => (
        <IconButton
          size="small"
          target={"_blank"}
          href={path}
          sx={{
            background: "#012A5E",
            marginRight: 1,
            "&:hover": {
              background: "#0f5cbf",
            },
          }}
          key={`${i}`}
        >
          {icon}
        </IconButton>
      ))}
    </Stack>
  );
}

const Footer = (props) => {
  const [openFreeAccountModal, setOpenFreeAccountModal] = useState(false);
  const location = useLocation();
  const handleOpenFreeAccount = () => {
    setOpenFreeAccountModal(true);
  };
  const handleCloseFreeAccount = () => {
    setOpenFreeAccountModal(false);
  };
  return (
    <footer>
      {location.pathname !== "/company-registration" && (
        <Box sx={{ background: "#012A5E" }}>
          <Container>
            <Box className="topFooter">
              <Typography sx={{ textAlign: "center" }} color="white">
                Hey! Are You Packers & Movers? We have a solution to manage your
                business online.
              </Typography>
               {/* <Link to="/#signin"> 
              <Button variant="contained" onClick={handleOpenFreeAccount}>
                Get A Free Account
              </Button>
               </Link>  */}
            </Box>
          </Container>
        </Box>
      )}
      <section className="CardsWrapper" style={{ margin: "30px 0" }}>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={3}>
              <Card className="card">
                <CardHeader className="cardHeading" title={"WHAT WE DO"} />
                <Divider />
                <CardContent>
                  <Typography gutterBottom>
                    ePackBook is a powerfull software to manage relocation
                    business online. ePackBook allows their user to manage all
                    enquiries, Notify survey date & Time, Follow ups reminder,
                    Create and Send Quotations in stylish tamplets, Jobs
                    reminder, Download Reports, Manage customers profile & Their
                    Moves and many more...
                  </Typography>
                  <Typography href={"/about-us"} component={"a"}>
                    Read more...
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} lg={3}>
              <Card className="card">
                <CardHeader className="cardHeading" title={"KEEP IN TOUCH"} />
                <Divider />
                <CardContent>
                  <SocialMediaIconsJSX
                    IconsArr={props.footerCardData[1].socialMediaIcons}
                  />
                </CardContent>
                <CardContent>
                  <Typography gutterBottom>Contact ePackBook</Typography>
                  <Link to={"/contact"}>
                    <Typography fontWeight={600}>Contact us</Typography>
                  </Link>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} lg={3}>
              <Card className="card">
                <CardHeader
                  className="cardHeading"
                  title={"DOCUMENTATION & SUPPORT"}
                />
                <Divider />
                <CardContent>
                  <Stack spacing={2}>
                    <Typography>How to use ePackBook:</Typography>
                    <Typography>
                      <Link to={"/knowledgeBase-page"}>ePackBook Support</Link>
                    </Typography>

                    <Typography>Developers? Leads Providers?</Typography>
                    <Typography>ePackBook Terms & Privacy</Typography>
                    <Typography>
                      <Link to={"/terms-of-services"}>Terms of Service</Link>
                    </Typography>
                    <Typography>
                      <Link to={"/privacy-policy"}>Privacy Policy</Link>
                    </Typography>
                    <Typography>
                      <Link to={"/refund-policy"}>Refund Policy</Link>
                    </Typography>
                    <Typography>Contact Support</Typography>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} lg={3}>
              <Card className="card">
                <CardHeader
                  className="cardHeading"
                  title={"EPACKBOOK ADDRESS"}
                />
                <Divider />
                <CardContent>
                  <Typography gutterBottom>
                    Unit No. 537-538, Spaze iTech Park, Tower B3, Sector 49,
                    Gurugram-122018, Haryana, INDIA
                  </Typography>
                  <Typography gutterBottom fontWeight={600}>
                    Support- 9355771100
                  </Typography>
                  <Typography gutterBottom fontWeight={600}>
                    info@epackbook.in
                  </Typography>
                  <Stack spacing={1}>
                    <Typography>
                      <Link to={"/about-us"}>About Us</Link>
                    </Typography>
                    <Typography>
                      <Link to={"/features"}>Features</Link>
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </section>
      <Divider color="#fff" />
      <section style={{ margin: "16px 0" }}>
        <Container>
          <Typography sx={{ textAlign: "center", color: "#fff" }}>
            © ePackBook {new Date().getFullYear()} | All Right Reserved
          </Typography>
        </Container>
      </section>
      <VerificationModal
        open={openFreeAccountModal}
        handleClose={handleCloseFreeAccount}
        heading={"Get Free Account"}
      />
    </footer>
  );
};

Footer.defaultProps = {
  footerCardData: [
    {
      header: "WHAT WE DO",
      body: "ePackBook is a powerfull software to manage relocation business online. ePackBook allows their user to manage all enquiries, Notify survey date & Time, Follow ups reminder, Create and Send Quotations in stylish tamplets, Jobs reminder, Download Reports, Manage customers profile & Their Moves and many more...",
    },
    {
      header: "this is a header",
      socialMediaIcons: [
        {
          icon: <FaFacebookF color="white" size={18} />,
          path: "https://www.facebook.com/people/EPackBook/100070560356502/",
        },
        {
          icon: <FaLinkedinIn color="white" size={18} />,
          path: "https://www.linkedin.com/in/epackbook-relocationsoftware-b539b7150/",
        },
        {
          icon: <IoLogoTwitter color="white" size={18} />,
          path: "https://twitter.com/ePackBook",
        },
        // {
        //   icon: <BsFacebook color="#012A5E" size={30} />,
        //   path: "#",
        // },
      ],
    },
    {
      header: "this is a header",
      body: "this is a body",
    },
    {
      header: "this is a header",
      body: "this is a body",
    },
  ],
};

export default Footer;
