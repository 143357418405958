import { Box } from "@mui/system";
import react from "react";
import "./Style.css";


const RoleManagementView =() =>{

    return(
        <Box className="roleManagement">
        <h1>RoleManagementView</h1>
        </Box>
    )
};
export default RoleManagementView;