import { Box, Typography } from "@mui/material";
import ComponentSubHeader from "../UIComponents/ComponentSubHeader";

const PaymentSuccess = () => {
  return (
    <>
      <ComponentSubHeader
        heading={"Payment Success"}
        current={"Payment Success"}
      />
      <Box sx={{ textAlign: "center",height:'40vh' }}>
        <Typography variant="h3" color={'#5cb85c'}>
          We have successfully received your payment.
        </Typography>
        <Typography variant="h4" marginTop={2} color={'#00A1E5'}>Thank you very much.</Typography>
      </Box>
    </>
  );
};
export default PaymentSuccess;
