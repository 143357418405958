import { forwardRef, Fragment, useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Slide,
  TextField,
  Toolbar,
  CircularProgress,
  Typography,
} from "@mui/material";
import { IoMdClose } from "react-icons/io";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  sendEmailRequst,
  resetSendEmailState,
  verifyEmailRequst,
  resetAll,
  resetVerifyEmailState,
} from "../../../Redux/Slices/FreeAccount.Slice";
import toast from "react-hot-toast";
import { useTimer } from "react-timer-hook";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const checkIsPhoneNumber = (value) => {
  return parseInt(value).toString().length === value.length;
};

const RESEND_TIMER_ACTIVE_SECONDS = 300;

const GETOTP_FORM = ({ handleNextStep }) => {
  const dispatch = useDispatch();
  const { requesting, success, fail, data } = useSelector(
    (state) => state.FreeAccountSlice
  );

  const formik = useFormik({
    initialValues: {
      emailOrMobile: "",
    },
    validate: (values) => {
      const errors = {};
      const emailRegExp = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+?\.[a-zA-Z]{2,3}$/;
      if (!values.emailOrMobile) {
        errors.emailOrMobileErr = "please enter moble number or email";
      }
      if (
        checkIsPhoneNumber(values.emailOrMobile) &&
        values.emailOrMobile.length !== 10
      ) {
        errors.emailOrMobileErr = "please enter valid mobile number";
      }
      if (
        !checkIsPhoneNumber(values.emailOrMobile) &&
        !emailRegExp.test(values.emailOrMobile)
      ) {
        errors.emailOrMobileErr = "please enter valid email";
      }
      return errors;
    },
    validateOnChange: false,
    onSubmit: (values) => {
      const data = { resend_otp: false };
      if (checkIsPhoneNumber(formik.values.emailOrMobile)) {
        data.phone_number = formik.values.emailOrMobile;
      } else {
        data.email = formik.values.emailOrMobile;
      }
      dispatch(sendEmailRequst(data));
    },
  });

  useEffect(() => {
    if (!requesting && success) {
      // next step
      toast.success(data, {
        id: "send_email_success",
      });
      const userInput = formik.values.emailOrMobile;
      formik.resetForm();
      dispatch(resetSendEmailState());
      handleNextStep(userInput);
    } else if (!requesting && fail) {
      formik.setFieldError("apiError", data.message);
      dispatch(resetSendEmailState());
      formik.setSubmitting(false);
    }
  }, [requesting, success, fail]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Typography>
            Please enter your Email or Mobile Number to get an OTP
          </Typography>
        </Grid>

        <Grid item xs={12} md={8}>
          <TextField
            id="emailOrMobile"
            placeholder="Enter Email Or Mobile Number"
            fullWidth
            size="small"
            variant="outlined"
            onChange={(e) => {
              const { value } = e.target;
              formik.setFieldValue("emailOrMobile", value.trim().toLowerCase());
            }}
            value={formik.values.emailOrMobile}
            error={formik.errors.emailOrMobileErr}
            helperText={formik.errors.emailOrMobileErr}
            disabled={formik.isSubmitting}
          />
          <FormHelperText error>{formik.errors.apiError}</FormHelperText>
        </Grid>

        <Grid item xs={12} md={4}>
          <Button
            startIcon={
              formik.isSubmitting && (
                <CircularProgress size={20} color="primary" />
              )
            }
            variant="contained"
            type="submit"
            disabled={formik.isSubmitting}
          >
            Get OTP
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const VERIFY_OTP_FORM = ({ handleResetStep, userEmailOrMobile }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { seconds, minutes, isRunning, start, restart, pause } = useTimer({
    expiryTimestamp: new Date().setSeconds(
      new Date().getSeconds() + RESEND_TIMER_ACTIVE_SECONDS
    ),
    autoStart: true,
  });

  const {
    verifying,
    verifySuccess,
    verifyFail,
    verifyData,
    success,
    data,
    fail,
  } = useSelector((state) => state.FreeAccountSlice);

  const checkIsPhoneNumber = (value) => {
    return /^[\d]+$/.test(value) ? true : false;
  };

  const formik = useFormik({
    initialValues: {
      emailOrMobile: userEmailOrMobile,
      otp: "",
      terms: false,
    },
    validate: (values) => {
      const errors = {};
      if (!values.otp) {
        errors.otpErr = "please enter otp";
      }
      if (!values.terms) {
        errors.termsErr = "Please check service agreement";
      }
      console.log(errors, values);
      return errors;
    },
    validateOnChange: false,
    onSubmit: (values) => {
      const { otp, terms, emailOrMobile } = values;
      const data = { otp };
      if (checkIsPhoneNumber(emailOrMobile)) {
        data.phone_number = emailOrMobile;
      } else {
        data.email = emailOrMobile;
      }
      dispatch(verifyEmailRequst(data));
    },
  });

  const handleResendOTP = () => {
    if (formik.values.emailOrMobile) {
      // restarting timer
      restart(
        new Date().setSeconds(
          new Date().getSeconds() + RESEND_TIMER_ACTIVE_SECONDS
        ),
        true
      );

      const data = { resend_otp: true };
      if (checkIsPhoneNumber(formik.values.emailOrMobile)) {
        data.phone_number = formik.values.emailOrMobile;
      } else {
        data.email = formik.values.emailOrMobile;
      }
      dispatch(sendEmailRequst(data));
    }
  };

  useEffect(() => {
    if (!verifying && verifySuccess) {
      toast.success(verifyData.message, {
        id: "verify_email_success",
      });
      formik.resetForm();
      dispatch(resetVerifyEmailState());
      // pausing timer
      pause();
      navigate("/company-registration");
    } else if (!verifying && verifyFail) {
      formik.setFieldError("apiError", verifyData.message);
      formik.setSubmitting();
    }
  }, [verifying, verifyFail]);

  useEffect(() => {
    if (success) {
      toast.success(data, {
        id: "send_email_success",
      });
      dispatch(resetSendEmailState());
    } else if (fail) {
      formik.setFieldError("apiError", data.message);
      dispatch(resetSendEmailState());
    }
  }, [success, fail]);

  useEffect(() => {
    start();
  }, []);

  const showMinutes = String(minutes).padStart(2, 0);
  const showSeconds = String(seconds).padStart(2, 0);

  const handleOtp = (value) => {
    const valOTP = value.slice(0, 4);
    formik.setFieldValue("otp", valOTP);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          {checkIsPhoneNumber(formik.values.emailOrMobile) ? (
            <Typography>
              OTP is sent to this mobile number {formik.values.emailOrMobile}
            </Typography>
          ) : (
            <Typography>
              OTP is sent to this email {formik.values.emailOrMobile}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="otp"
            fullWidth
            placeholder="Enter OTP"
            size="small"
            type={"number"}
            value={formik.values.otp}
            onChange={(e) => handleOtp(e.target.value)}
            error={formik.errors.otpErr && formik.errors.otpErr}
            helperText={formik.errors.otpErr && formik.errors.otpErr}
          />
          <FormHelperText error>{formik.errors.apiError}</FormHelperText>
        </Grid>

        <Grid item xs={8}>
          Time remaining to expire OTP -{" "}
          <b>{`${showMinutes}:${showSeconds}`}</b>
        </Grid>
        <Grid item xs={4}>
          <Button
            disabled={isRunning}
            variant="text"
            style={{ padding: 0 }}
            onClick={handleResendOTP}
          >
            Resend OTP
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Box>
            <FormControl
              required
              error={Boolean(formik.errors.termsErr)}
              variant="standard"
            >
              <FormControlLabel
                control={
                  <Fragment>
                    <Checkbox {...formik.getFieldProps("terms")} size="small" />
                    <Typography variant="subtitle2">
                      I agree to the Main
                      <a href="#" style={{ marginLeft: 6 }}>
                        Services Agreement.
                      </a>
                    </Typography>
                  </Fragment>
                }
              />

              <FormHelperText error>{formik.errors.termsErr}</FormHelperText>
            </FormControl>
          </Box>

          <Typography variant="caption">
            By registering, you confirm that you agree to the storing and
            processing of your personal data by ePackBook as described in the{" "}
            <Link to="/privacy-policy">Privacy Policy</Link>.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Button
            startIcon={
              formik.isSubmitting && (
                <CircularProgress size={20} color="primary" />
              )
            }
            disabled={formik.isSubmitting}
            type="submit"
            variant="contained"
            fullWidth
          >
            Verify
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const Getfreeaccount = ({ open, handleClose, heading }) => {
  const dispatch = useDispatch();
  const INITIAL_STEP = 0;
  const [activeStep, setActiveStep] = useState(INITIAL_STEP);
  const [userEmailOrMobile, setUserEmailOrMobile] = useState(null);

  const handleNextStep = (userInputValue) => {
    setUserEmailOrMobile(userInputValue);
    setActiveStep((pre) => pre + 1);
  };

  const handleBackStep = () => {
    setActiveStep((pre) => pre - 1);
  };

  const handleResetStep = () => {
    setActiveStep(INITIAL_STEP);
  };

  const _handleClose = () => {
    window.location.hash = "";
    handleResetStep();
    handleClose();
  };

  useEffect(() => {
    if (open) {
      dispatch(resetAll());
      setUserEmailOrMobile(null);
      handleResetStep();
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted={false}
      maxWidth="sm"
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar variant="dense">
          <Typography variant="h6">{heading}</Typography>
          <IconButton
            edge="start"
            color="inherit"
            type="button"
            onClick={_handleClose}
            style={{ marginLeft: "auto" }}
          >
            <IoMdClose />
          </IconButton>
        </Toolbar>
      </AppBar>

      <DialogContent>
        <Stepper activeStep={activeStep} orientation="vertical">
          {[
            {
              component: <GETOTP_FORM handleNextStep={handleNextStep} />,
              label: "Generate OTP",
            },
            {
              component: (
                <VERIFY_OTP_FORM
                  handleResetStep={handleResetStep}
                  userEmailOrMobile={userEmailOrMobile}
                />
              ),
              label: "Verify OTP",
            },
          ].map((step, index) => (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
              <StepContent>{step.component}</StepContent>
            </Step>
          ))}
        </Stepper>
      </DialogContent>
    </Dialog>
  );
};

export default Getfreeaccount;
